import { Texture } from "three";
import { FsBaseFile } from "./base";
import { IFsFileType } from "@r3f-component-data-structure";
import { HlsProgress } from "@r3f-component-data-structure/types/videoStatus";

export type HlsPlaylistType = 'auto' | 'low' | 'high' | 'hd' | 'vr';

export class FsVideo extends FsBaseFile {
    type: IFsFileType.Video | IFsFileType.Video360 = IFsFileType.Video
    protected _mp4Url: string
    protected _thumbnailUrl: string
    protected _progressUrl: string
    protected _cacheBlobs: {[id: string]: Blob} = {}
    protected _thumbnailTexture: Texture | undefined
    protected _hasAlpha: boolean = false

    constructor(fileId: string, projectId: string, name: string) {
        super(fileId, projectId, name)
        if (this.id.startsWith('https://')) {
            this.baseUrl = this.id
        }
        const mp4Url = new URL(this.baseUrl)
        mp4Url.pathname = `${mp4Url.pathname}/video.mp4`
        this._mp4Url = mp4Url.toString()

        const thumbnailUrl = new URL(this.baseUrl)
        thumbnailUrl.pathname = `${thumbnailUrl.pathname}/frame0.png`
        this._thumbnailUrl = thumbnailUrl.toString()

        const progressUrl = new URL(this.baseUrl)
        progressUrl.pathname = `${progressUrl.pathname}/status.json`
        this._progressUrl = progressUrl.toString()
    }

    /**
     * Returns an HLS playlist URL for the video
     */
    getPlaylistUrl(type: HlsPlaylistType = 'auto') {
        const url = new URL(this.baseUrl)
        url.pathname = `${url.pathname}/${type}.m3u8`
        return url.toString()
    }

    getMp4Url() {
        return this._mp4Url
    }

    getThumbnailUrl() {
        return this._thumbnailUrl
    }

    async getAspectRatio() {
        return 16 / 9
    }

    async getThumbnailBlob() {
        const url = this.getThumbnailUrl()
        if (typeof this._cacheBlobs[url] !== 'undefined') return this._cacheBlobs[url]
        const req = await fetch(url)
        this._cacheBlobs[url] = await req.blob()
        return this._cacheBlobs[url]
    }

    async getThumbnailTexture() {
        if (typeof this._thumbnailTexture === 'undefined') {
            const blob = await this.getThumbnailBlob()
            this._thumbnailTexture = new Texture()
            this._thumbnailTexture.image = await createImageBitmap(blob)
            this._thumbnailTexture.needsUpdate = true
        }
        return this._thumbnailTexture
    }

    getHasAlpha() {
        return this._hasAlpha
    }

    setHasAlpha(hasAlpha: boolean) {
        this._hasAlpha = hasAlpha
    }

    async getProgressData() {
        const progressReq = await fetch(this._progressUrl);
        const progress = await progressReq.json() as HlsProgress;
        return { ...progress, progressUrl: this._progressUrl }
    }

    async getFilesForBundler() {
        return [
            { path: this.getPlaylistUrl() },
            { path: this._mp4Url },
            { path: this._thumbnailUrl }
        ]
    }
}
