import React from 'react';
import { COLORS } from '../../utils';
import { IIconParentProps } from './index';

const Info20: React.FunctionComponent<IIconParentProps> = ({ hexFill: fill, viewBox, className, width, height, onClick = () => {} }) => {
	const hexFill = fill || COLORS.midnight;
	return (
		<svg
			onClick={onClick}
			className={className || ''}
			width={width ? width : '20px'}
			height={height ? height : '20px'}
			viewBox={viewBox ? viewBox.join(' ') : `0 0 20 20`}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g clipPath="url(#clip0_13837_43525)">
				<path
					d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM10.5 8V15H9.5V8H10.5ZM9.98819 5C10.0906 5 10.188 5.01969 10.2805 5.05906C10.373 5.09843 10.4537 5.15256 10.5226 5.22146C10.5915 5.29035 10.6467 5.37106 10.688 5.46358C10.7293 5.5561 10.75 5.65354 10.75 5.75591C10.75 5.85827 10.7293 5.95374 10.688 6.04232C10.6467 6.13091 10.5915 6.20965 10.5226 6.27854C10.4537 6.34744 10.373 6.40157 10.2805 6.44094C10.188 6.48031 10.0906 6.5 9.98819 6.5C9.88583 6.5 9.79035 6.48031 9.70177 6.44094C9.61319 6.40157 9.53543 6.34744 9.4685 6.27854C9.40158 6.20965 9.34843 6.13091 9.30906 6.04232C9.26968 5.95374 9.25 5.85827 9.25 5.75591C9.25 5.65354 9.26968 5.5561 9.30906 5.46358C9.34843 5.37106 9.40158 5.29035 9.4685 5.22146C9.53543 5.15256 9.61319 5.09843 9.70177 5.05906C9.79035 5.01969 9.88583 5 9.98819 5Z"
					fill={hexFill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_13837_43525">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Info20;
