import { IFsFileType } from "@/components/r3f/r3f-components/component-data-structure";
import { FsVideo, HlsPlaylistType } from "./video";

export class FsVideo360 extends FsVideo {
    type: IFsFileType.Video360 = IFsFileType.Video360

    getPlaylistUrl(type: HlsPlaylistType = 'vr') {
        const url = new URL(this.baseUrl)
        url.pathname = `${url.pathname}/${type}.m3u8`
        return url.toString()
    }
}
