import { ModelData, parseGLB } from "@/utils/gltf";
import { FsBaseFile } from "./base";
import { IFsFileType } from "@/components/r3f/r3f-components/component-data-structure";

export class FsModel3D extends FsBaseFile {
    type: IFsFileType.Model3d = IFsFileType.Model3d
    private _cdnModelUrl: string
    private _cdnThumbnailUrl: string
    private _parsedData: ModelData | undefined

    constructor(fileId: string, projectId: string, name: string) {
        super(fileId, projectId, name)
        const modelUrl = new URL(this.baseUrl)
        modelUrl.pathname = `${modelUrl.pathname}/model.glb`
        this._cdnModelUrl = modelUrl.toString()
        const thumbnailUrl = new URL(this.baseUrl)
        thumbnailUrl.pathname = `${thumbnailUrl.pathname}/frame0.png`
        this._cdnThumbnailUrl = thumbnailUrl.toString()
    }

    async getUrl() {
        return this.fs.getObjectUrl(this._cdnModelUrl, await this.getBlob())
    }

    async getBlob() {
        return this.fs.getFileBlob(this._cdnModelUrl)
    }

    async getArrayBuffer() {
        return (await this.getBlob()).arrayBuffer()
    }

    async getModelData(force = false) {
        if (typeof this._parsedData === 'undefined' || force)
            this._parsedData = parseGLB(await this.getArrayBuffer())
        return this._parsedData
    }

    async getThumbnailUrl() {
        const blob = await this.fs.getFileBlob(this._cdnThumbnailUrl)
        if (blob.size === 0) return undefined
        return this.fs.getObjectUrl(this._cdnThumbnailUrl, blob)
    }

    async getFilesForBundler() {
        return [{
            path: `fs/${this.id}/model.glb`,
            bin: new Uint8Array(await this.getArrayBuffer())
        }]
    }
}
