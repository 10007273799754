import {
	ROOT_COMPNENT_ID,
	IComponentType,
	IContentDoc,
} from '.';

export const CONTENT_DOC_VERSION = 23;

export const initCnDoc: () => IContentDoc = () => {
	return {
		version: CONTENT_DOC_VERSION,
		rootComponentId: ROOT_COMPNENT_ID,
		projectColors: [],
		componentsById: {
			[ROOT_COMPNENT_ID]: {
				type: IComponentType.Root,
				children: [],
			}
		},
		mediaFilesById: {}
	}
}
