import { ILaunchScreen } from '@/components/r3f/r3f-components/component-data-structure/types/launchScreen';
import { DEFAULT_LAUNCH_SCREEN } from '@/settings';
import { ToastsData } from '@/utils/toasts-data';
import { useThree } from '@react-three/fiber';
import { image_target_type_t } from '@zappar/zappar-cv';
import React, { useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { IActiveEditingActionData, IDesignerState, IMediaLibraryOnSubmitCategory, IModal, IStoreType } from '../../typings';
import { IMenuItemType } from '../components/dom/menus/MenuList/MenuList';
import { IVideoProgress } from '../components/dom/modals/VideoProcessingWarningModal/VideoProcessingList/VideoProcessingList';
import {
	IImageTrackingOrientation,
	ITrackingTypes,
	IComponentType,
	IComponentUnion,
	IRoot,
	ISceneComp,
	IScreenAnchorGroup,
	IScreenAnchorPositionType,
	IScreenContent,
	ISpatialComponentUnion,
	ITriggerTypes,
	IVideo,
	ITuple4,
	IFaceLandmarkGroup,
	ICurveComponentUnion,
	IButtonSubCategory,
	IButton,
	IText,
	IButtonCategory,
	ISpatialComponentActionData,
	IActionCategory,
} from '../components/r3f/r3f-components/component-data-structure';
import { HlsProgress } from '../components/r3f/r3f-components/component-data-structure/types/videoStatus';
import { clearHlsCacheById } from '../components/r3f/r3f-components/hooks';
import { return360VideoIdBySceneId } from '../components/r3f/r3f-components/utils/content360';
import { isAbstractComponent, isCurveComponent } from '../components/r3f/r3f-components/utils/general';
import { store } from '../store';
import { onAddToast, onOpenModal, onSetProjectSize, onSetProjectSizeLimit, onSetVideoProgress } from '../store/actions';
import { onSetComponentAction_Cn_Doc, onSetVideoRenditionProgressData_Cn_Doc } from '../store/actions/automerge/content-doc-actions';
import { getAllActionTargetIdsFromEntityForTrigger } from './component-action-utils';
import { createCanvasR3fComponents, getProjectSizeLimitFromString, getSpatialSrComponentsForScene, isLastWorldInStringMB } from './general';

export const useOnUnmountActionPopout = (store: IStoreType, dispatch: Dispatch, editingAction: IActiveEditingActionData, editingActionType: IActionCategory, data: React.MutableRefObject<ISpatialComponentActionData>) => {
	useEffect(() => {
		return () => {
			// Do not update content doc when the type in the content doc doesn't match. This is when the user has changed the action category in the drop down menu.
			if (typeof editingAction?.actionIndex === 'undefined' || !editingAction.entityId || !editingAction.triggerType) return;
			const component = store.getState().contentReducer.contentDoc.componentsById[editingAction?.entityId];
			if (!component) return;
			if (isAbstractComponent(component) && component.type !== IComponentType.Scene) return;
			const currentActionType = component?.actions?.[editingAction.triggerType]?.[editingAction.actionIndex]?.type;
			if (currentActionType !== editingActionType) return;

			// Update content doc with updated state
			dispatch(
				onSetComponentAction_Cn_Doc({
					entityId: editingAction.entityId,
					index: editingAction.actionIndex,
					triggerType: editingAction.triggerType,
					actionData: { ...data.current },
				})
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const useGetActionByEntityIdAndIndex = (entityId: string, trigger: ITriggerTypes, actionIndex: number): ISpatialComponentActionData | undefined => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!entityId || !trigger || actionIndex === undefined) return;
	const entity = componentsById[entityId] as ISpatialComponentUnion;
	return entity?.actions?.[trigger]?.[actionIndex];
}

export const useGetOpenModal = () => {
	const openModalId = useSelector((state: IDesignerState) => {
		const modalById = state.userReducer.modalById;
		const modalId = Object.entries(modalById).find(([_key, val]) => {
			return val === true;
		});
		return modalId?.[0] ?? null;
	});

	return openModalId as IModal | null;
};

export const useTimeOutOnMount = (cb: () => unknown, ms: number) => {
	useEffect(() => {
		const timout = setTimeout(() => cb(), ms);
		return () => clearTimeout(timout);
	}, []);
};

export const useComponentWillMount = (fn: () => unknown) => {
	const willMount = useRef(true);
	if (willMount.current) {
		fn();
	}
	willMount.current = false;
};

export const useGetScreenRelativeDomCanvasAspectRatio = () => {
	const { gl } = useThree();
	return gl.domElement.width / gl.domElement.height;
};

export const useIsWorldTrackingScene = () => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	return (componentsById[activeSceneId] as ISceneComp)?.trackingType === ITrackingTypes.world;
};

export const useGetActiveSceneTrackingType = () => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	return (componentsById[activeSceneId] as ISceneComp)?.trackingType || ITrackingTypes.world;
};

export const useIsActiveSceneFirstScene = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const rootId = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.rootComponentId);
	const firstSceneId = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[rootId] as ISceneComp).children[0]);
	return activeSceneId == firstSceneId;
};

export const useIsFlatOrientation = () => {
	return useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.tracking?.[ITrackingTypes.image]?.orientation === IImageTrackingOrientation.flat);
};

export const useIsReplaceEntityMode = () => {
	return useSelector((state: IDesignerState) => state.userReducer.mediaLibraryDisplay.onSubmitCategory == IMediaLibraryOnSubmitCategory.ReplaceEntity || false);
};

export const useIsCurvedImageTrackedScene = () => {
	return useSelector((state: IDesignerState) => {
		const activeSceneId = state.userReducer.activeSceneId;
		if (!activeSceneId) return false;
		const isImageTrackedScene = (state.contentReducer.contentDoc.componentsById[activeSceneId] as ISceneComp)?.trackingType === ITrackingTypes.image;
		const isCurvedImageData =
			state.contentReducer.contentDoc?.tracking?.image?.targetType !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR &&
			typeof state.contentReducer.contentDoc?.tracking?.image?.targetType !== 'undefined';
		return isImageTrackedScene && isCurvedImageData;
	});
};

export const useActiveSceneHas3dContent = () => {
	return useSelector((state: IDesignerState) => {
		const activeSceneId = state.userReducer.activeSceneId;
		if (!activeSceneId) return false;
		const { componentsById } = state.contentReducer.contentDoc;
		const { children = [] } = (componentsById[activeSceneId] as ISceneComp) || {};

		return children.some((id) => {
			const component = componentsById[id];
			if (isAbstractComponent(component)) {
				return false;
			}
			return (
				component.scale[2] !== 0 ||
				component.position[2] !== 0 ||
				component.rotation[0] !== 0 ||
				component.rotation[1] !== 0 ||
				component.type === IComponentType.Model3d ||
				component.type === IComponentType.Emitter ||
				!!(component as ICurveComponentUnion).isSnappedToTarget ||
				!!(component as ICurveComponentUnion).curvature
			);
		});
	});
};

export const useGetSiblings = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const trackingType = useGetActiveSceneTrackingType();
	const screenComponentIds = useGetActiveScreenComponentIds();
	const faceTrackedComponentIds = useGetFaceTrackedComponentIds();

	if (isScreenRelativeMode) return screenComponentIds;
	if (trackingType === ITrackingTypes.face) return faceTrackedComponentIds;

	return (componentsById[activeSceneId!] as ISceneComp).children.filter((id) => componentsById[id].type !== IComponentType.ScreenContent);
};

export const useGetEntityTransientColors = (id: string) => {
	const transientBorderRgba = useSelector((state: IDesignerState) => (state.userReducer.borderRgbaById?.[id] as ITuple4) || null);
	const transientFontRgba = useSelector((state: IDesignerState) => (state.userReducer.fontRgbaById?.[id] as ITuple4) || null);
	const transientFillRgba = useSelector((state: IDesignerState) => (state.userReducer.fillRgbaById?.[id] as ITuple4[]) || null);
	return {
		transientBorderRgba,
		transientFontRgba,
		transientFillRgba,
	};
};

export const useGetActiveArComponentIds = () => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
	if (!activeSceneId) return [];
	const scene = componentsById[activeSceneId] as ISceneComp;
	if (!scene) return [];
	if (scene?.trackingType !== ITrackingTypes.face) return scene?.children.filter((entityId) => !isAbstractComponent(componentsById[entityId])) || [];
	const faceLandmarkGroupIds = scene.children.filter((componentId) => componentsById[componentId].type == IComponentType.FaceLandmarkGroup);
	return faceLandmarkGroupIds.reduce((acc, faceLandmarkId) => {
		const faceLandmarkGroup = componentsById[faceLandmarkId] as IFaceLandmarkGroup;
		return [...acc, ...faceLandmarkGroup.children];
	}, [] as string[]);
};

export const useGetSceneEntities = (enabled = true, isPreview = false) => {
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const isReplaceEntityMode = useIsReplaceEntityMode();
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const sceneTrackingType = useGetActiveSceneTrackingType();
	const hiddenEntityIds = useSelector((state: IDesignerState) => {
		return Object.keys(state.contentReducer.contentDoc.componentsById).filter((entityId) => !!(state.contentReducer.contentDoc.componentsById[entityId] as ISpatialComponentUnion).isHidden);
	});

	// Canvas content
	const canvasContent = useMemo(() => {
		return createCanvasR3fComponents({
			ids: activeScene?.children || [],
			hiddenEntityIds,
			componentsById,
			enabled,
			sceneTrackingType,
			isPreview,
		});
	}, [activeScene?.children?.length, hiddenEntityIds?.length, activeSceneId, isScreenRelativeMode, isReplaceEntityMode, sceneTrackingType, Object.keys(componentsById)]);
	return canvasContent;
};

export const useIsArModeDisabled = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
	return useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[activeSceneId || ''] as ISceneComp)?.trackingType === ITrackingTypes.noTrackingScreen);
};

export const useGetSceneIdList = () => {
	const rootId = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.rootComponentId);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	return (componentsById[rootId] as IRoot).children;
};

// Get the anchor group ID at position argument for the currently active scene
export const useGetAnchorGroupId = (position: IScreenAnchorPositionType) => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const screenContentId = useGetActiveSceneScreenContentId();
	const anchorGroupIds = (componentsById[screenContentId] as IScreenContent).children;
	for (let i = 0; i < anchorGroupIds.length; i++) {
		const anchorGroup = componentsById[anchorGroupIds[i]];
		if ((anchorGroup as IScreenAnchorGroup).anchorPositionType == position) {
			return anchorGroupIds[i];
		}
	}
	return '';
};

// Does the active scene have a snapshot UI?
export const useGetSnapshotUiId = () => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const screenComponentIds = useGetActiveScreenComponentIds(false);
	for (let i = 0; i < screenComponentIds.length; i++) {
		const component = componentsById[screenComponentIds[i]] as ISpatialComponentUnion;
		if (component.type == IComponentType.Button && (component.subCategory === IButtonSubCategory.snapshot || component.subCategory === IButtonSubCategory.recording)) return component.id;
	}
	return null;
};

export const useGetSceneCameraButtonType = () => {
	const snapshotButtonId = useGetSnapshotUiId();
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!snapshotButtonId) return null;
	return (componentsById[snapshotButtonId] as IButton).subCategory || null;
};

// Returns an array of options for use in a <Dropdown> component
export const useGetSceneOptions = (disableActive = true) => {
	const initialOptions = [
		{
			text: 'Select a scene...',
			val: 'default',
		},
		{
			text: 'All scenes',
			val: 'allScenes',
			type: IMenuItemType.standard,
		},
	];

	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const sceneIds = useGetSceneIdList();
	return [
		...initialOptions,
		...sceneIds.map((sceneId) => {
			return {
				val: sceneId,
				text: (componentsById[sceneId] as ISceneComp).title,
				type: disableActive && sceneId == activeSceneId ? IMenuItemType.disabled : IMenuItemType.standard,
			};
		}),
	];
};

// Gets the screen relative scene ID for the currently active scene
export const useGetActiveSceneScreenContentId = (): string => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	return useGetSceneScreenContentId(activeSceneId);
};

// Gets the screen components for the currently active scene
export const useGetActiveScreenComponentIds = (filterOutFunctionalButtons = true): string[] => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	return useGetScreenComponents(activeSceneId, filterOutFunctionalButtons);
};

export const useGetFaceTrackedComponentIds = (): string[] => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);

	const activeScene = componentsById[activeSceneId] as ISceneComp;

	const entityIds: string[] = [];
	if (!activeScene) return [];
	for (let i = 0; i < activeScene.children.length; i++) {
		const id = activeScene.children[i];
		const component = componentsById[id];
		if (component.type === IComponentType.FaceLandmarkGroup) {
			entityIds.push(...component.children);
		}
	}

	return entityIds;
};

// Gets the screen relative scene ID, given a scene ID where sceneId is the ID of ISceneComp component
export const useGetSceneScreenContentId = (sceneId: string): string => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeSceneChildren = (componentsById[sceneId] as ISceneComp)?.children || [];
	return activeSceneChildren.filter((id) => componentsById[id].type === IComponentType.ScreenContent)[0];
};

export const useIs360ContentMissing = (): boolean => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const rootId = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.rootComponentId);
	const rootChildren = (componentsById[rootId] as IRoot)?.children || [];

	return rootChildren.filter(id => {
		const scene = componentsById[id] as ISceneComp;
		return scene.trackingType === ITrackingTypes.content360 && !scene.content360?.filestoreId
	}).length > 0;
}

// Gets the screen components for a given scene where sceneId is the ID of ISceneComp component
export const useGetScreenComponents = (sceneId: string, filterOutFunctionalButtons = false): string[] => {
	const screenSceneId = useGetSceneScreenContentId(sceneId);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!screenSceneId) return []; // If no scene ID match

	const activeScreenContent = componentsById[screenSceneId] as IScreenContent;

	return activeScreenContent.children.reduce((idArray, anchorId) => {
		const anchorGroup = componentsById[anchorId] as IScreenAnchorGroup;
	
		const anchorChildren = filterOutFunctionalButtons ? anchorGroup.children.filter(id => { 
			const entity = componentsById[id];
			if (isAbstractComponent(entity)) return false;
			if (entity.type === IComponentType.Button && entity.category === IButtonCategory.functional) {
				return false;
			}
			return true;
		}) : anchorGroup.children;

		return [...idArray, ...anchorChildren];
	}, [] as string[]);
};

export const useGetActiveScreenAnchorGroupIdDict = () => {
	const activeSceneScreenContentId = useGetActiveSceneScreenContentId();
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeScreenContent = componentsById[activeSceneScreenContentId] as IScreenContent;

	return activeScreenContent.children.reduce((dict, anchorId) => {
		const anchorGroup = componentsById[anchorId] as IScreenAnchorGroup;
		dict[anchorGroup.anchorPositionType] = anchorId;
		return dict;
	}, {} as { [k in IScreenAnchorPositionType]: string });
};

export const useGetAnchorPositionByEntityIds = (ids: string[] | undefined, activeScreenContentId: string): null | undefined | IScreenAnchorPositionType => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!ids) return undefined;
	const screenContent = componentsById[activeScreenContentId] as IScreenContent;
	const anchorPositions: IScreenAnchorPositionType[] = [];

	for (let i = 0; i < ids.length; i++) {
		screenContent.children.forEach((id) => {
			const anchorGroup = componentsById[id] as IScreenAnchorGroup;
			if (anchorGroup.children.includes(ids[i])) {
				anchorPositions.push(anchorGroup.anchorPositionType);
			}
		});
	}

	const allPositionsEqual = anchorPositions.every((val) => val === anchorPositions[0]);

	return allPositionsEqual ? anchorPositions[0] : null;
};

export const useSceneTitleDict = (): { [id: string]: string } => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	return Object.keys(componentsById).reduce((sceneTitleDict, entityId) => {
		if (componentsById[entityId].type === IComponentType.Scene) sceneTitleDict[entityId] = (componentsById[entityId] as ISceneComp).title!;
		return sceneTitleDict;
	}, {} as { [id: string]: string });
};

export const useEntityActionsHaveChanged = (entity: ISpatialComponentUnion) => {
	return useMemo(() => {
		return entity.hasOwnProperty('actions');
	}, [entity]);
};

export const useEntityTransitionsHaveChanged = (entity: ISpatialComponentUnion) => {
	return useMemo(() => {
		return (
			(entity as ISpatialComponentUnion).hasOwnProperty('transitions') &&
			((entity as ISpatialComponentUnion).transitions?.hasOwnProperty('enter') || (entity as ISpatialComponentUnion).transitions?.hasOwnProperty('exit'))
		);
	}, [entity]);
};

// TODO - fix font issue in here
export const useEntityAppearanceHasChanged = (entity: ISpatialComponentUnion) => {
	return useMemo(() => {
		switch (entity.type) {
			case IComponentType.Button:
				if (!entity.svgUrl) {
					// 2 types of non-SVG button - white filled / blue filled - covered here
					// Possible edge case user changes white preset to blue preset, won't flag as changed
					// but without recording initial type in additional state, no way to know
					// Similarly 3x preset border radius' are valid as 'non-changed'
					if (entity.borderRadius !== 0.35 && entity.borderRadius !== 0 && entity.borderRadius !== 1) return true;
					if (entity.borderWidth !== 0 && entity.borderWidth !== 0.01) return true;
					if (entity.fontFamily !== 'Roboto') return true;
					if (entity.fontSize !== 0.11) return true;
					if (entity.textAlignment !== 'center') return true;
					if (
						(entity.color[0] !== 150 || entity.color[1] !== 191 || entity.color[2] !== 239 || entity.color[3] !== 1) &&
						(entity.color[0] !== 255 || entity.color[1] !== 255 || entity.color[2] !== 255 || entity.color[3] !== 0)
					)
						return true;
					if (
						(entity?.borderRgba?.[0] !== 52 || entity.borderRgba[1] !== 75 || entity.borderRgba[2] !== 96 || entity.borderRgba[3] !== 1) &&
						(entity?.borderRgba?.[0] !== 150 || entity.borderRgba[1] !== 191 || entity.borderRgba[2] !== 239 || entity.borderRgba[3] !== 1)
					)
						return true;
					if (
						(entity.fontRgba[0] !== 255 || entity.fontRgba[1] !== 255 || entity.fontRgba[2] !== 255 || entity.fontRgba[3] !== 1) &&
						(entity.fontRgba[0] !== 150 || entity.fontRgba[1] !== 191 || entity.fontRgba[2] !== 239 || entity.fontRgba[3] !== 1)
					)
						return true;
					return false;
				} else {
					if (entity.borderRadius !== 0) return true;
					if (entity.borderWidth !== 0) return true;
					if (entity.fontFamily !== 'Roboto') return true;
					if (entity.fontSize !== 0.11) return true;
					if (entity.textAlignment !== 'center') return true;
					if (entity.color[0] !== 255 || entity.color[1] !== 255 || entity.color[2] !== 255 || entity.color[3] !== 0) return true;
					if (entity.borderRgba?.[0] !== 150 || entity.borderRgba?.[1] !== 191 || entity.borderRgba?.[2] !== 239 || entity.borderRgba[3] !== 1) return true;
					if (entity.fontRgba[0] !== 255 || entity.fontRgba[1] !== 255 || entity.fontRgba[2] !== 255 || entity.fontRgba[3] !== 0) return true;
					return false;
				}
			case IComponentType.Image:
			case IComponentType.Video:
				if (entity.hasOwnProperty('opacity') && entity.opacity !== 1) return true;
				if (entity.hasOwnProperty('borderRadius') && entity.borderRadius !== 0) return true;
				if (entity.hasOwnProperty('borderWidth') && entity.borderWidth !== 0) return true;
				if (entity.hasOwnProperty('borderRgba') && (entity?.borderRgba?.[0] !== 150 || entity.borderRgba[1] !== 191 || entity.borderRgba[2] !== 239 || entity.borderRgba[3] !== 1)) return true;
				return false;
			case IComponentType.Text:
				if (entity.fontFamily !== 'Roboto') return true;
				// These cover heading, subheading and paragraph default colors
				if (
					(entity.fontRgba[0] !== 52 || entity.fontRgba[1] !== 75 || entity.fontRgba[2] !== 96 || entity.fontRgba[3] !== 1) &&
					(entity.fontRgba[0] !== 124 || entity.fontRgba[1] !== 137 || entity.fontRgba[2] !== 150 || entity.fontRgba[3] !== 1) &&
					(entity.fontRgba[0] !== 102 || entity.fontRgba[1] !== 114 || entity.fontRgba[2] !== 125 || entity.fontRgba[3] !== 1)
				)
					return true;
				return false;
			case IComponentType.Model3d:
				return false; // Has no appearance shelf
		}
	}, [entity]);
};

export const useProjectHasScenesWithImageTracking = (componentsById: { [id: string]: IComponentUnion }, rootId: string) => {
	return useMemo(() => {
		const sceneIds = (componentsById[rootId] as IRoot)?.children;
		for (let i = 0; i < sceneIds.length; i++) {
			const sceneId = sceneIds[i];
			if ((componentsById[sceneId] as ISceneComp)?.trackingType === ITrackingTypes.image) {
				return true;
			}
		}
		return false;
	}, [componentsById, rootId]);
};

export const useGetActionTargetIdsByIdAndTrigger = (triggerType: ITriggerTypes, id: string) => {
	const componentDict = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	//const selectedId = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds![0]);
	return getAllActionTargetIdsFromEntityForTrigger(componentDict[id] as ISpatialComponentUnion, triggerType);
};

export const useAnimatedModelIdsOnActiveScene = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const faceTrackedEntityIds = useGetFaceTrackedComponentIds();
	const sceneChildren = useMemo(() => (componentsById[activeSceneId] as ISceneComp).children, [componentsById, activeSceneId]);
	return useMemo(() => {
		return [...sceneChildren, ...faceTrackedEntityIds].filter((entityId) => {
			const entity = componentsById[entityId];
			if (entity.type === IComponentType.Model3d) {
				return !!entity.animations?.length;
			}
			return false;
		});
	}, [sceneChildren, componentsById]);
};

export const useVideoIdsOnActiveScene = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const componentDict = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const activeScene = componentDict[activeSceneId] as ISceneComp;
	const faceTrackedEntityIds = useGetFaceTrackedComponentIds();
	const sceneChildren = useMemo(() => (componentDict[activeSceneId] as ISceneComp).children, [componentDict, activeSceneId]);
	const srSpatialComponents = getSpatialSrComponentsForScene(componentDict, activeScene);
	return useMemo(() => {
		return [...sceneChildren, ...faceTrackedEntityIds, ...srSpatialComponents].filter((entityId) => componentDict[entityId].type == IComponentType.Video);
	}, [sceneChildren, componentDict]);
};

export const useEmitterIdsOnActiveScene = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const componentDict = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const faceTrackedEntityIds = useGetFaceTrackedComponentIds();
	const sceneChildren = useMemo(() => (componentDict[activeSceneId] as ISceneComp).children, [componentDict, activeSceneId]);
	return useMemo(() => {
		return [...sceneChildren, ...faceTrackedEntityIds].filter((entityId) => componentDict[entityId].type == IComponentType.Emitter);
	}, [sceneChildren, componentDict]);
};

export const useSpatialEntityIdsOnActiveScene = () => {
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const faceTrackedEntityIds = useGetFaceTrackedComponentIds();
	const screenComponentIds = useGetActiveScreenComponentIds();
	const sceneChildren = useMemo(() => (componentsById[activeSceneId] as ISceneComp).children, [componentsById, activeSceneId]);
	return useMemo(() => {
		return [...sceneChildren, ...faceTrackedEntityIds, ...screenComponentIds].filter((entityId) => !isAbstractComponent(componentsById[entityId]));
	}, [sceneChildren, faceTrackedEntityIds, componentsById, screenComponentIds]);
};

export const useOnClickOutside = (ref: React.MutableRefObject<HTMLElement | null>, cb: (e: PointerEvent) => unknown) => {
	const onClickOutside = (event: PointerEvent) => {
		const outsideMenuClick = ref.current && !ref.current.contains(event.target as HTMLElement);
		if (outsideMenuClick) {
			cb(event);
		}
	};

	useEffect(() => {
		document.addEventListener('pointerdown', onClickOutside);
		return () => {
			document.removeEventListener('pointerdown', onClickOutside);
		};
	}, []);
};

export const useActiveSceneVideoTitlesDict = () => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const selectedId = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds?.[0]);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const arComponents = useGetActiveArComponentIds();
	const srSpatialComponents = getSpatialSrComponentsForScene(componentsById, activeScene);

	return useMemo(() => {
		if (!componentsById) return {};
		return [...arComponents, ...srSpatialComponents].reduce((acc, entityId) => {
			if (componentsById[entityId]?.type === IComponentType.Video && selectedId !== entityId) {
				acc[entityId] = (componentsById[entityId] as IVideo).title!;
			}
			return acc;
		}, {} as { [id: string]: string });
	}, [componentsById, activeSceneId, selectedId]);
};

export const useActiveSceneEntityTitles = (): { [id: string]: string } => {
	const faceTrackedEntityIds = useGetFaceTrackedComponentIds();
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId as string);
	const sceneEntityIds = useSelector((state: IDesignerState) => (state.contentReducer.contentDoc.componentsById[activeSceneId] as ISceneComp)?.children || []);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const srSpatialComponents = getSpatialSrComponentsForScene(componentsById, activeScene);

	return [...sceneEntityIds, ...srSpatialComponents, ...faceTrackedEntityIds].reduce((sceneScopedTitles, entityId) => {
		const component = componentsById[entityId];
		if (isAbstractComponent(component)) return sceneScopedTitles;
		sceneScopedTitles[entityId] = component.title;
		return sceneScopedTitles;
	}, {} as { [id: string]: string });
};

export const useIsSnappedCurvedEntitySelected = () => {
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	return useSelector((state: IDesignerState) => {
		const entity = selectedEntityIds && selectedEntityIds[0] ? state.contentReducer.contentDoc.componentsById[selectedEntityIds[0]] : null;
		if (entity && isCurveComponent(entity) && entity.isSnappedToTarget) return true;
		return false;
	});
};

export const useIsEverySelectedEntityLocked = (): boolean => {
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!selectedEntityIds) return false;
	return selectedEntityIds?.every((componentId) => {
		const component = componentsById[componentId];
		if (isAbstractComponent(component)) return false; // should never happen
		return component.isLocked;
	});
};

export const useGetMultiSelectedFontHeight = () => {
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!selectedEntityIds) return null;

	const buttonsAndText = selectedEntityIds.filter((componentId) => {
		const component = componentsById[componentId];
		return component.type === IComponentType.Button || component.type === IComponentType.Text || component.type === IComponentType.Text3d;
	});
	if (buttonsAndText.length === 0) return null;

	const firstFontHeight = (componentsById[buttonsAndText[0]] as IButton | IText).fontSize;
	const allFontsSameFontHeight = buttonsAndText.every((componentId) => {
		const component = componentsById[componentId] as IButton | IText;
		return component.fontSize === firstFontHeight;
	});

	return allFontsSameFontHeight ? firstFontHeight : null;
};

export const useGetMultiSelectedFontFamily = () => {
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!selectedEntityIds) return null;

	const buttonsAndText = selectedEntityIds.filter((componentId) => {
		const component = componentsById[componentId];
		return component.type === IComponentType.Button || component.type === IComponentType.Text || component.type === IComponentType.Text3d;
	});
	if (buttonsAndText.length === 0) return null;

	const firstFontFamily = (componentsById[buttonsAndText[0]] as IButton | IText).fontFamily;
	const allFontsSameFontFamily = buttonsAndText.every((componentId) => {
		const component = componentsById[componentId] as IButton | IText;
		return component.fontFamily === firstFontFamily;
	});

	return allFontsSameFontFamily ? firstFontFamily : null;
};

export const useGetAllMultiSelectedFontFamily = () => {
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!selectedEntityIds) return null;

	const buttonsAndText = selectedEntityIds.filter((componentId) => {
		const component = componentsById[componentId];
		return component.type === IComponentType.Button || component.type === IComponentType.Text || component.type === IComponentType.Text3d;
	});
	if (buttonsAndText.length === 0) return null;

	const allSelectedFonts = buttonsAndText.map((componentId) => {
		const component = componentsById[componentId] as IButton | IText;
		return component.fontFamily;
	});

	return allSelectedFonts;
};

export const useGetAllMultiSelectedTextAlignment = () => {
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	if (!selectedEntityIds) return null;

	const buttonsAndText = selectedEntityIds.filter((componentId) => {
		const component = componentsById[componentId];
		return component.type === IComponentType.Button || component.type === IComponentType.Text || component.type === IComponentType.Text3d;
	});
	if (buttonsAndText.length === 0) return null;

	const firstAlignment = (componentsById[buttonsAndText[0]] as IButton | IText).textAlignment;
	const allFontsSameAlignment = buttonsAndText.every((componentId) => {
		const component = componentsById[componentId] as IButton | IText;
		return component.textAlignment === firstAlignment;
	});

	return allFontsSameAlignment ? firstAlignment : null;
};

export const useGetCurrentSceneId = () => {
	const rootId = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.rootComponentId);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const firstSceneId = (componentsById[rootId] as IRoot).children[0];
	return activeSceneId ?? firstSceneId;
}

export const useGetNextSceneId = () => {
	const rootId = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.rootComponentId);
	const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId!);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	const sceneIds = (componentsById[rootId] as IRoot).children;
	const currentSceneIndex = sceneIds.indexOf(activeSceneId ?? sceneIds[0]);
	
	if (currentSceneIndex === sceneIds.length - 1) return sceneIds[0];
	return sceneIds[currentSceneIndex + 1];
}

export const useGetCurrentScene = () => {
	const currentSceneId = useGetCurrentSceneId();
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
	return componentsById[currentSceneId] as ISceneComp;
}

export const useGetActiveSceneBackgroundData = () => {
	const currentScene = useGetCurrentScene();
	return currentScene?.content360;
}

interface IIntervalCache {
	[id: string]: {
		progressUrl: string;
		intervalId: NodeJS.Timeout;
	}
}

const intervalCache: IIntervalCache = {}

export const useVideoRenditionStatusHelpers = (ids: string[], _ms?: number) => {
	const dispatch = useDispatch()
	const ms = _ms ?? 1000;

	for (let i = 0; i < ids.length; i++) {
		const id = ids[i];
		if (!intervalCache[id]) (intervalCache as any)[id] = {};
	}

	const startVideoRenditionStatusIntervalCheck = () => {
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			const { status } = store.getState().contentReducer?.contentDoc?.videoStatusDict?.[id] ?? {};
			if (intervalCache[id].intervalId) clearInterval(intervalCache[id].intervalId);
			if (status === "error") return; // TODO: handle error correctly in ui
			if (status === "complete") {
				clearHlsCacheById(return360VideoIdBySceneId(id));
				dispatch(onSetVideoProgress({id, progress: 100}))
				return;
			}
			intervalCache[id].intervalId = setInterval(async () => {
				const {progressUrl, status} = store.getState().contentReducer?.contentDoc?.videoStatusDict?.[id] ?? {}
				if (!progressUrl || !status) return;
				const hlsJobStatus = await fetch(progressUrl);
				const hlsJobStatusData = await hlsJobStatus.json() as HlsProgress;
				dispatch(onSetVideoRenditionProgressData_Cn_Doc({id, progressUrl, status: hlsJobStatusData.status}))
				dispatch(onSetVideoProgress({id, progress: hlsJobStatusData.progress}))
				if (hlsJobStatusData.status === "error") clearInterval(intervalCache[id].intervalId)
				if (hlsJobStatusData.status === "complete" || hlsJobStatusData.progress === 100) {
					clearHlsCacheById(return360VideoIdBySceneId(id));
					dispatch(onSetVideoRenditionProgressData_Cn_Doc({id, progressUrl, status: 'complete'}))
					dispatch(onSetVideoProgress({id, progress: hlsJobStatusData.progress}))
					clearInterval(intervalCache[id].intervalId)
				}
			}, ms)
		}
	}

	const clearVideoRenditionStatusIntervalCheck = () => {
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			clearInterval(intervalCache[id].intervalId)
		}
	}

	return {startVideoRenditionStatusIntervalCheck, clearVideoRenditionStatusIntervalCheck}
}

export const useGetVideoProgressData = (): IVideoProgress[] => {
	const videoStatusDict = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.videoStatusDict)
	const videoProgressById = useSelector((state: IDesignerState) => state.userReducer.videoProgressById)
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById)

	const data: IVideoProgress[] = []
	if (!videoProgressById) return data;
	for (const key in videoStatusDict) {
	
		const {status} = videoStatusDict[key];
		if (status === 'error') continue;
		const scene = componentsById[key] as ISceneComp;
		if (!scene?.content360) continue;
		const {title} = scene.content360;
		const progress = videoProgressById[key];
		data.push({status, title, progress})

	}
	return data;
}

export const useGetLaunchScreenData = (): ILaunchScreen => {
	const projectInfo = useSelector((state: IDesignerState) => state.userReducer.project);
	let launchScreen = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.launchScreen);

	if (!!launchScreen) {
		// Fill any missing data with default launch screen data
		launchScreen = {
			...DEFAULT_LAUNCH_SCREEN,
			...launchScreen
		};

		launchScreen.fields = {
			...DEFAULT_LAUNCH_SCREEN.fields,
			...(launchScreen.fields ?? {})
		};
	} else {
		launchScreen = DEFAULT_LAUNCH_SCREEN;
		launchScreen.fields.title.value = (projectInfo as any).metaTitle ?? '';
		launchScreen.fields.iconUrl.value = (projectInfo as any).metaIconUrl ?? '';
	}
  return launchScreen;
}

export interface IAPIError {
	code: string;
	message: string;
	size?: string;
	sizeLimit?: string;
	sizeLimitBytes?: number;
}

export const useGetPublishErrorHandler = () => {
	const dispatch = useDispatch();
	return (err: IAPIError, defaultToastError: typeof ToastsData.PreviewError | typeof ToastsData.PublishError) => {
		if (err.code === 'ProjectSizeLimitExceeded') {
			const projectSize = parseFloat(err.size!.split(" ")[0]);
			dispatch(onSetProjectSizeLimit(Math.round(err.sizeLimitBytes! * 0.00000095367432)));
			dispatch(onSetProjectSize(projectSize));
			dispatch(onOpenModal(IModal.projectSizeLimit));
		} else {
			dispatch(onAddToast(defaultToastError));
		}	
	}
}