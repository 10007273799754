import * as Sentry from '@sentry/react';
import { Store } from 'redux';
import { IBaseToast, IDesignerState } from '../../typings';
import { cdsClient } from './cds';
import { CONTENT_DOC_VERSION, IContentDoc } from '../components/r3f/r3f-components/component-data-structure';
import * as settings from '../settings';
import { 
	IChange_Cn_Doc_Action, 
	IContentDocActionTypes,  
	IOnAddToastAction, 
	ISetPRojectInfoAction, 
	ISetSceneSnapshotsAction, 
	ISetUserIdAction, 
	IUserActionTypes, 
	IOnSetSocketConnectedAction,
	IOnSetProjectLoadingProgressAction,
	IOnSetProjectLoadingFailureAction,
	IOnChangeZoomLevelAction,
	IOnUpdateUsers_Action,
	IOnSetSelection_Action,
 } from '../store/actions';
import { getSnapshotDictFromLStorage, removeExpiredSceneSnapshots } from '../utils';
import { ToastsData } from '../utils/toasts-data';
import { WEBSOCKET_ACTIONS } from './websocket';
import { zwClient } from './zapworks';
import { SyncDocClient } from './doc';

export interface ISyncDocUser {
	id: string;
	name: string;
	email?: string;
	avatarUrl: string | null;
}

export let projectId = location.pathname.replace(/\//g, '');

export const setupWebSocket = async (store: Store): Promise<typeof cdsClient | void> => {
	// if no project id, re-direct to my.zap.works
	if (!projectId || projectId.length === 0) {
		window.location.href = settings.ZW_BASE_URL;
		return;
	}

	// TODO: use react router to handle callback
	try {
		await zwClient.checkForAuthorizationResponse();

		// 5% loading progress
		store.dispatch<IOnSetProjectLoadingProgressAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
			payload: 5
		})

		const qs = new URLSearchParams(location.search);
		if (qs.get('error')) return; // TODO: display auth error
		if (qs.get('code')) {
			projectId = window.atob(qs.get('state') || '').replace('p', '');
			if (projectId === '') {
				console.error('Invalid project ID:', projectId)
				return;
			}
			// projectId = Buffer.from(qs.get('state') || '', 'base64').toString('ascii');
			history.replaceState({}, document.title, `/${projectId}/`); // change URL from /callback/?code=... to /123/
			await zwClient.makeTokenRequest(); // get access token

			// 20% loading progress
			store.dispatch<IOnSetProjectLoadingProgressAction>({
				type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
				payload: 20
			})

			if (projectId) {
				store.dispatch<ISetPRojectInfoAction>({
					type: IUserActionTypes.SET_PROJECT_INFO,
					payload: { project: { id: projectId, title: '' } }
				})
			}
		} else {
			clearLocalStorageAuthKeys() // Start a fresh new auth process
			zwClient.makeAuthorizationRequest(
				'user:read account:read media:read media:write project:read project:write project:publish',
				window.btoa(`p${projectId}`)
			); // redirect the user to ZapWorks login
			return;
		}
	} catch (err) {
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: ToastsData.AuthorizationError
		})
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		Sentry.captureException(err);
	}

	clearLocalStorageAuthKeys() // Auth process is complete, clean local storage
	const accessToken = zwClient.getAccessToken()
	cdsClient.setAccessToken(accessToken)
	cdsClient.setProjectId(projectId)
	const req = await fetch(`${settings.CDS_URL}${projectId}`, {
		headers: {
			'X-Authorization': `Basic ${accessToken}`,
			'ZW-Environment': settings.ZW_ENV
		}
	})
	if (req.status === 200) { // Automerge docs exist, start the websocket
		const response = await req.json();
		// response.previousVersion < response.docs.content.version --> 'migrated'
		const contentDocVersion = response.content.version;
		cdsClient.automergeDocsFound = true;
		if (contentDocVersion !== CONTENT_DOC_VERSION) {
			store.dispatch<IOnAddToastAction>({
				type: IUserActionTypes.ADD_TOAST,
				payload: ToastsData.MismatchedVersionError
			});
			return;
		}
		return await createWebSocket(store)
	}
	else if (req.status === 404) return cdsClient;
	else if (req.status === 403) {
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: ToastsData.NoAccessGrantedError
		});
		return;
	}
	// TODO: display a pretty user message
	throw 'Unable to get project'
}

export const forkProject = async (source: string, destination: string) => {
	await cdsClient.forkProject(source, destination)
}

export const createWebSocket = async (store: Store) => {
	cdsClient.addEventListener('error', (ev: any) => {
		let errorPayload: IBaseToast | undefined, sendErrorReport = true;
		if (ev.detail.errorType === 'config') {
			if (ev.detail.errorCode >= 500) {
				errorPayload = ToastsData.InternalServerError;
			} else if (ev.detail.errorCode === 403) {
				errorPayload = ToastsData.NoAccessGrantedError;
				sendErrorReport = false
			} else if (ev.detail.errorCode === 401) {
				errorPayload = ToastsData.SessionExpiredError;
				sendErrorReport = false
			}
			cdsClient.stopReconnecting();
		}
		store.dispatch<IOnAddToastAction>({
			type: IUserActionTypes.ADD_TOAST,
			payload: errorPayload || ToastsData.SocketConnectionError
		})
		store.dispatch<IOnSetProjectLoadingFailureAction>({
			type: IUserActionTypes.SET_PROJECT_LOADING_FAILURE,
			payload: true
		})
		if (sendErrorReport) {
			Sentry.withScope(scope => {
				scope.setExtra('eventDetail', ev.detail)
				Sentry.captureMessage('CDS client error')
			})
		}
	})

	cdsClient.addEventListener('ws:config', (ev: any) => {
		document.title = `${ev.detail.project.title} | Zapworks Designer`;
		Sentry.setUser({ id: ev.detail.user.id });
		Sentry.setExtra('project', ev.detail.project.id);

		store.dispatch<ISetUserIdAction>({
			type: IUserActionTypes.SET_USER_ID,
			payload: { userId: ev.detail.user.id },
		})
		store.dispatch<ISetPRojectInfoAction>({
			type: IUserActionTypes.SET_PROJECT_INFO,
			payload: { project: ev.detail.project },
		})
	})

	cdsClient.addEventListener('ws:open', async () => {
		store.dispatch<IOnSetSocketConnectedAction>({
			type: IUserActionTypes.SET_SOCKET_CONNECTED,
			payload: true
		})
	})

	let docsReadyResolve: () => void | undefined;
	const docsReadyPromise = new Promise<void>((resolve) => {
		docsReadyResolve = resolve
	})
	cdsClient.addEventListener('syncdoc:ready', async (ev: any) => {
		await syncDocReadyHandler(store, ev.detail)
		docsReadyResolve()
	})

	cdsClient.addEventListener('ws:close', async () => {
		store.dispatch<IOnSetSocketConnectedAction>({
			type: IUserActionTypes.SET_SOCKET_CONNECTED,
			payload: false
		})
	})

	const socket = await cdsClient.connectToWebSocket()
	if (typeof socket === 'undefined') return;

	socket.addMessageListener(WEBSOCKET_ACTIONS.UpdateUsers, async (users: ISyncDocUser[]) => {
		await docsReadyPromise
		store.dispatch<IOnUpdateUsers_Action>({
			type: IUserActionTypes.UPDATE_USERS,
			payload: { users }
		})
	})

	store.dispatch<IOnSetProjectLoadingProgressAction>({
		type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
		payload: 80
	})

	await docsReadyPromise
	store.dispatch<IOnSetProjectLoadingProgressAction>({
		type: IUserActionTypes.SET_PROJECT_LOADING_PROGRESS,
		payload: 100
	})

	return cdsClient
}

const syncDocReadyHandler = async (store: Store, syncDoc: SyncDocClient<IContentDoc>) => {
	removeExpiredSceneSnapshots(); // remove any expired snapshots (from other projects too)

	syncDoc.addEventListener('sync', async () => {
		await syncDoc.waitForDoc()
		const contentDoc = syncDoc.getDoc();
		const { selectedEntityIds } = (store.getState() as IDesignerState).userReducer;
		const componentIds = Object.keys(contentDoc.componentsById);
		const filtSelEntityIds = selectedEntityIds?.filter(id => componentIds.includes(id));

		store.dispatch<IOnSetSelection_Action>({
			type: IUserActionTypes.SET_SELECTED_ENTIY_IDS,
			payload: filtSelEntityIds || [],
		})

		store.dispatch<IChange_Cn_Doc_Action>({
			type: IContentDocActionTypes.CHANGE_CN_DOC_REDUX,
			contentDoc,
		})
	})

	const zoom = parseFloat(localStorage.getItem(`${projectId}_zoom_level`) || '')
	if (typeof zoom === 'number' && !isNaN(zoom)) {
		store.dispatch<IOnChangeZoomLevelAction>({
			type: IUserActionTypes.CHANGE_ZOOM_LEVEL, 
			payload: zoom
		})
	}

	await syncDoc.waitForDoc()
	const doc = syncDoc.getDoc()
	if (typeof doc.componentsById === 'undefined') {
		Sentry.captureMessage("Content doc's componentsById is undefined", {
			extra: { contentDoc: JSON.parse(JSON.stringify(doc)) }
		})
	}
	store.dispatch<IChange_Cn_Doc_Action>({
		type: IContentDocActionTypes.CHANGE_CN_DOC_REDUX,
		contentDoc: doc,
	})

	store.dispatch<ISetSceneSnapshotsAction>({
		type: IUserActionTypes.SET_SCENE_SNAPSHOTS,
		payload: getSnapshotDictFromLStorage(localStorage, projectId),
	})
}

function clearLocalStorageAuthKeys() {
	for (const key in localStorage) {
		if (key.includes('appauth_')) {
			localStorage.removeItem(key)
		}
	}
}
