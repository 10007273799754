import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '@/../typings';
import SelectedEntityPositionHotspot from '../Hotspots/SelectedEntityPositionHotspot';
import { IVideoReactProps, IVideo, ITuple3 } from '@r3f-component-data-structure';
import EntityBoundaries from './EntityBoundaries/EntityBoundaries';
import {
    useGetHocBorderProperties, useGetHocCurvedProperties, useGetHocSpatialProperties, useHocPointerDown,
    useHocPointerMove, useHocPointerUp
} from './hocCustomHooks';
import { getEntityRenderOrder } from './hocUtils';
import { filestore, FsVideo } from '@/filestore';

interface IVideoWrapped {
	id: string;
	enabled?: boolean;
}
// http://127.0.0.1:3000/3820099643859120715/

const withVideoBehaviour = (WrappedVideo: FunctionComponent<IVideoReactProps>): FunctionComponent<IVideoWrapped> => {
	const FuncComp: FunctionComponent<IVideoWrapped> = ({ enabled: entityIsEnabled = true, id }) => {
		// Selectors
		const entity = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById[id] as IVideo);
		const projectShadowsEnabled = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.shadowsEnabled  ?? true);

		// Refs
		const pointerStageRef = useRef<'down' | 'move' | 'up' | null>(null);
		const shiftLockedRef = useRef<number | null>(null);
		const initialPosRef = useRef<ITuple3 | null>(null); // Used to calculate pointer offset in pointerMove
		const previousPositionRef = useRef<ITuple3 | null>(null); // The previous position, used to compare with the current position
		const pointerUpHasRunRef = useRef<boolean | null>(null); // To prevent multiple dispatched actions when pointerUp runs twice this is reset onPointerMove
		const [showEntityPositionHotspot, setShowEntityPositionHotspot] = useState(false);

		// Entity properties to pass to wrapped component
		const renderOrder = getEntityRenderOrder(entity);
		const { opacity, hasAlpha, autoplay, chromaKey, castShadow = false, receiveShadow = false } = entity;
		const { scale, position, rotation } = useGetHocSpatialProperties(entity);
		const { borderWidth, borderRadius, borderRgba } = useGetHocBorderProperties(entity);
		const { curvature } = useGetHocCurvedProperties(entity);

		// Pointer events
		const onPointerDown = useHocPointerDown({ pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot });
		const onPointerMove = useHocPointerMove({ pointerStageRef, initialPosRef, pointerUpHasRunRef, previousPositionRef, entity, shiftLockedRef });
		const onPointerUp = useHocPointerUp({ pointerStageRef, pointerUpHasRunRef, initialPosRef, entity, setShowEntityPositionHotspot, shiftLockedRef });
		const key = JSON.stringify(chromaKey);
		// console.log('On editor canvas, chromaKey data is: ', chromaKey)
		// console.log('Stringified: ', JSON.stringify(chromaKey))

        const [videoUrl, setVideoUrl] = useState<string | undefined>();
        const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();
        useEffect(() => {
            const fsFile = filestore.load<FsVideo>(entity.filestoreId)
            setVideoUrl(fsFile.getPlaylistUrl())
            setThumbnailUrl(fsFile.getThumbnailUrl())
        }, [entity.filestoreId])

        if (typeof videoUrl === 'undefined' || typeof thumbnailUrl === 'undefined') return <>Loading</>;

		return (
			<>
				<EntityBoundaries
					position={position}
					rotation={rotation}
					scale={scale}
					renderOrder={renderOrder}
					onPointerDown={entityIsEnabled ? onPointerDown : undefined}
					onPointerUp={onPointerUp}
					label={`Video component ${id} render order ${renderOrder}`}
				>
					<WrappedVideo						
						id={id}
						key={key}
						chromaKey={chromaKey}
						scale={scale}
						rotation={rotation}
						position={position}
						videoUrl={videoUrl}
						thumbnailUrl={thumbnailUrl}
						thumbnailOnly={true}						
						curvature={curvature}
						hideControls={autoplay}
						renderOrder={renderOrder}
						opacity={opacity}
						hasAlpha={hasAlpha}
						borderWidth={borderWidth}
						borderRgba={borderRgba}
						borderRadius={borderRadius}
						castShadow={castShadow && projectShadowsEnabled}
						receiveShadow={receiveShadow && projectShadowsEnabled}
						onPointerDown={entityIsEnabled ? onPointerDown : undefined}
						onPointerUp={entityIsEnabled ? onPointerUp : undefined}
					/>
				</EntityBoundaries>
				{showEntityPositionHotspot && <SelectedEntityPositionHotspot onPointerMove={showEntityPositionHotspot ? onPointerMove : undefined} onPointerUp={entityIsEnabled ? onPointerUp : undefined} />}
			</>
		);
	};
	return FuncComp;
};

export default withVideoBehaviour;
