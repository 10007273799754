import { FsBaseFile } from "./base";
import { IFsFileType } from "@/components/r3f/r3f-components/component-data-structure";

export class FsAudio extends FsBaseFile {
    type: IFsFileType.Audio = IFsFileType.Audio
    protected _cdnUrl: string

    constructor(fileId: string, projectId: string, name: string) {
        super(fileId, projectId, name)
        const url = new URL(this.baseUrl)
        url.pathname = `${url.pathname}/aud.mp3`
        this._cdnUrl = url.toString()
    }

    async getUrl() {
        return this.fs.getObjectUrl(this._cdnUrl, await this.getBlob())
    }

    async getBlob() {
        return this.fs.getFileBlob(this._cdnUrl)
    }

    async getFilesForBundler() {
        const blob = await this.getBlob()
        return [{
            path: `fs/${this.id}/aud.mp3`,
            bin: new Uint8Array(await blob.arrayBuffer())
        }]
    }
}
