import * as settings from '@/settings';
import { IFsFileData, IFsFileType } from '@r3f-component-data-structure';
import { filestore, Filestore } from '../store';
import { formatBytes } from '../utils';


export class FsBaseFile implements IFsFileData {
    fs: Filestore
    id: string
    type: IFsFileType = IFsFileType.Unknown
    baseUrl: string
    size: number = 0
    name: string = ''

    constructor(fileId: string, projectId: string, name: string) {
        this.fs = filestore
        this.id = fileId
        this.name = name
        this.baseUrl = `${settings.FILESTORE_URL}/proj-${projectId}/${this.id}`
    }

    getSize() {
        return formatBytes(this.size)
    }

    async getFilesForBundler(): Promise<{ path: string, bin?: Uint8Array }[]> {
        return Promise.resolve([])
    }
}
