import React from 'react';
import { WebsiteIconSvg } from '../assets/icons';
import AnimateModel16 from '../assets/icons/AnimateModel16';
import AnimateParticle16 from '../assets/icons/AnimateParticle16';
import Camera16 from '../assets/icons/Camera16';
import Danger16 from '../assets/icons/Danger16';
import Expand16 from '../assets/icons/Expand16';
import Facebook16 from '../assets/icons/Facebook16';
import Globe16 from '../assets/icons/Globe16';
import Hash16 from '../assets/icons/Hash16';
import Instagram16 from '../assets/icons/Instagram16';
import Learn16 from '../assets/icons/Learn16';
import LinkedIn16 from '../assets/icons/LinkedIn16';
import Mail16 from '../assets/icons/Mail16';
import Message16 from '../assets/icons/Message16';
import Phone16 from '../assets/icons/Phone16';
import Plus16 from '../assets/icons/Plus16';
import Reddit16 from '../assets/icons/Reddit16';
import Scene16 from '../assets/icons/Scene16';
import Sound16 from '../assets/icons/Sound16';
import SoundCloud16 from '../assets/icons/SoundCloud16';
import Spotify16 from '../assets/icons/Spotify16';
import Star16 from '../assets/icons/Star16';
import TikTok16 from '../assets/icons/TikTok16';
import Twitch16 from '../assets/icons/Twitch16';
import Twitter16 from '../assets/icons/Twitter16';
import User16 from '../assets/icons/User16';
import View16 from '../assets/icons/View16';
import Vimeo16 from '../assets/icons/Vimeo16';
import YouTube16 from '../assets/icons/YouTube16';
import {
	IActionCategory,
	IBtnAndTxtActionData, IComponentsById, IComponentType, IEasing, IEmitter, IEmitterActionData, IImage, IImageActionData,
	IModel3dAnimationAction, IPresetAnimation, IPresetAnimationAction, ISceneComp, IScormEventType, ISocialProvider, ISpatialComponentActionData, ISpatialComponentUnion, ITriggerTypes
} from '../components/r3f/r3f-components/component-data-structure';
import {
	FacebookIcon, InstagramIcon, LinkedinIcon, RedditIcon, SoundcloudIcon, SpotifyIcon, TiktokIcon, TwitchIcon, TwitterIcon, VimeoIcon, YoutubeIcon
} from '../components/r3f/r3f-components/components/assets/icons';
import { isAbstractComponent } from '../components/r3f/r3f-components/utils/general';
import { addUrlScheme, capitalize, getUsernameFromSocialUrl, parseCustomUrl, parseEmailAddress, parsePhoneNumber } from '../utils/general';
import { ACTION_ERROR_MESSAGES } from './constants';
import { socialRegex as regex } from './social';

// Takes an action and returns a label for it, defaulting to noAction if no match
export const actionToLabel = (action?: ISpatialComponentActionData): IActionCategory => {
	if (!action) return IActionCategory.noAction;
	return action.type;
};

const finaliseTitle = (title?: string) => {
	return  title ? capitalize(title) : ' - '
}

// Takes an action, returns the title for designer
export const actionTitle = (action: ISpatialComponentActionData, componentsById: IComponentsById): string => {
	switch (action.type) {
		case IActionCategory.dialNumber: {
			return `Dial: ${action.tel || ' - '}`;
		}
		case IActionCategory.playSound: {	
			return `Play sound: ${finaliseTitle(action.title)}`;
		}
		case IActionCategory.composeEmail:
			return `Compose email: ${action.recipient || ' - '}`;
		case IActionCategory.animateModel: {
			const entityTitle = finaliseTitle((componentsById[action.targetIds[0]] as ISpatialComponentUnion)?.title);
			const animationName = finaliseTitle(action.animationName);
			return `${animationName}: ${entityTitle}`;
		}
		case IActionCategory.animatePreset: {
			const entityTitle = finaliseTitle((componentsById[action.targetIds[0]] as ISpatialComponentUnion)?.title);
			return `${finaliseTitle(action.entityAnimation)}: ${entityTitle}`;
		}
		case IActionCategory.enlarge: {
			const imageTitle = finaliseTitle((componentsById[action.entityId] as IImage)?.title)
			return `Enlarge: ${imageTitle}`;
		}
		case IActionCategory.linkPage: {
			return `Link to web: ${action.pageUrl || ' - '}`;
		}
		case IActionCategory.linkScene: {
			const sceneTitle = finaliseTitle((componentsById[action.sceneId!] as ISceneComp)?.title);
			return `Link to scene: ${sceneTitle}`;
		}
		case IActionCategory.linkSocial: {
			const {type: _t, ..._provider} = action;
			const providerKey = Object.keys(_provider)[0];
			let provider: string | undefined = finaliseTitle(providerKey);
			if (providerKey === 'custom') provider = _provider[providerKey];
			return `Link to social: ${provider}`;
		}
		case IActionCategory.playVideo: {
			const videoTitle = finaliseTitle((componentsById[action.targetIds[0]] as ISceneComp)?.title);
			return `Play video: ${videoTitle}`;
		}
		case IActionCategory.saveContact: {
			return `Save contact ${finaliseTitle(action.name)}`;
		}
		case IActionCategory.takePhoto:
			return 'Take photo';
		case IActionCategory.animateParticle: {
			const emitterTitle = finaliseTitle((componentsById[action.targetIds[0]] as IEmitter)?.title);
			return `Animate particle: ${emitterTitle}`;
		}
		case IActionCategory.toggleVisibility: {
			const entityTitle = finaliseTitle((componentsById[action.targetIds[0]] as ISpatialComponentUnion)?.title);
			return `Toggle visibility: ${entityTitle}`;
		}
		case IActionCategory.scorm: {
			return `Course event: ${capitalize(action.event)}`;
		}
		case IActionCategory.noAction:
			return 'No action set';
	}
	return '';
};

export const isEmptyTextFieldInActionData = (actionData?: ISpatialComponentActionData): boolean => {
	if (!actionData) return false;
	if (actionData.type === IActionCategory.linkPage) {
		if (!actionData.pageUrl.trim()) return true;
	}
	if (actionData.type === IActionCategory.playSound) {
		if (!actionData.filestoreId) return true;
	}
	if (actionData.type === IActionCategory.linkScene) {
		if (!actionData.sceneId) return true;
	}
	if (actionData.type === IActionCategory.linkSocial) {
		const socialProfileUrl = Object.values(ISocialProvider).reduce((acc, socialProvider) => {
			if (!!actionData[socialProvider]?.trim() && !acc) acc = true;
			return acc;
		}, false);
		return !socialProfileUrl;
	}
	if (actionData.type === IActionCategory.dialNumber) {
		if (!actionData.tel.trim()) return true;
	}
	if (actionData.type === IActionCategory.composeEmail) {
		if (!actionData.recipient.trim()) return true;
	}
	return false;
}

export const actionIcon = (action: ISpatialComponentActionData): JSX.Element => {
	// Check for missing fields
	if (isEmptyTextFieldInActionData(action)) {
		return <Danger16 />
	}

	switch (action.type) {
		case IActionCategory.animateParticle:
			return <AnimateParticle16 />
		case IActionCategory.dialNumber:
			return <Phone16 />;
		case IActionCategory.playSound:
			return <Sound16 />;
		case IActionCategory.composeEmail:
			return <Mail16 />;
		case IActionCategory.animateModel:
		case IActionCategory.animatePreset:
			return <AnimateModel16 />;
		case IActionCategory.linkPage:
			return <Globe16 />;
		case IActionCategory.linkScene:
			return <Scene16 />;
		case IActionCategory.linkSocial:
			return <Hash16 />;
		case IActionCategory.playVideo:
			return <Scene16 />;
		case IActionCategory.enlarge:
			return <Expand16 />;
		case IActionCategory.saveContact:
			return <User16 />;
		case IActionCategory.takePhoto:
			return <Camera16 />;
		case IActionCategory.toggleVisibility:
			return <View16 />;
		case IActionCategory.scorm:
			return <Learn16 />
	}
	return <></>;
};

export const newScormEventAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.scorm,
		event: IScormEventType.progress,
		progress: 0,
	};
};

export const newDialNumberAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.dialNumber,
		tel: '',
	};
};

export const newPlaySoundAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.playSound,
		filestoreId: '',
		stopMedia: false,
		loop: false,
		title: '',
	};
};

export const newComposeEmailAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.composeEmail,
		recipient: '',
		subject: '',
		body: '',
	};
};

export const newAnimateModelAction = (targetIds: string[], animationName: string): IModel3dAnimationAction => {
	return {
		type: IActionCategory.animateModel,
		targetIds,
		animationName: animationName,
		animationRepetitions: 1,
		animationRunCounter: 1,
	};
};

export const newAnimateEmitterAction = (targetIds: string[]): IEmitterActionData => {
	const action: IEmitterActionData = {
		type: IActionCategory.animateParticle,
		targetIds,
		behaviour: 'start',
		duration: null,
	};

	return action;
};

export const newToggleVisibilityAction = (targetIds: string[]): ISpatialComponentActionData => {
	return {
		type: IActionCategory.toggleVisibility,
		targetIds,
		behaviour: 'show',
	};
};

export const newPlayVideoAction = (targetIds: string[]): ISpatialComponentActionData => {
	return {
		type: IActionCategory.playVideo,
		targetIds,
	};
};

export const newLinkPageAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.linkPage,
		pageUrl: '',
	};
};

export const newLinkSocialAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.linkSocial,
	};
};

export const newTakePhotoAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.takePhoto,
	};
};

export const newEnlargeAction = (): IImageActionData => {
	return {
		type: IActionCategory.enlarge,
		entityId: '',
	} as IImageActionData;
};

export const newLinkSceneAction = (currentSceneId: string): ISpatialComponentActionData => {
	return {
		type: IActionCategory.linkScene,
		sceneId: currentSceneId,
	};
};

export const newAnimatePresetMoveAction = (targetId: string): IPresetAnimationAction => {
	return {
		type: IActionCategory.animatePreset,
		duration: 1000,
		delay: 0,
		loopCount: null,
		easing: IEasing.linear,
		backAndForth: true,
		entityAnimation: IPresetAnimation.move,
		resetOnFinish: false,
		position: [0, 0, 0],
		targetIds: [targetId]
	}
}

export const newAnimatePresetSpinAction = (targetId: string): IPresetAnimationAction => {
	return {
		type: IActionCategory.animatePreset,
		duration: 1000,
		delay: 0,
		loopCount: null,
		easing: IEasing.linear,
		backAndForth: true,
		entityAnimation: IPresetAnimation.spin,
		targetIds: [targetId],
		rotation: [0, -1, 0]
	}
}

export const newAnimatePresetScaleAction = (targetId: string): IPresetAnimationAction => {
	return {
		type: IActionCategory.animatePreset,
		duration: 1000,
		delay: 0,
		loopCount: null,
		easing: IEasing.linear,
		backAndForth: true,
		entityAnimation: IPresetAnimation.scale,
		targetIds: [targetId],
		multiplier: 1.5,
		resetOnFinish: false
	}
}

export const newAnimatePresetOrbitAction = (targetId: string): IPresetAnimationAction => {
	return {
		type: IActionCategory.animatePreset,
		duration: 3000,
		delay: 0,
		loopCount: null,
		easing: IEasing.linear,
		backAndForth: false,
		entityAnimation: IPresetAnimation.orbit,
		targetIds: [targetId],
		pivot: [0, 0, 0],
		angle: Math.PI*2,
		axis: [0, -1, 0]
	}
}

export const newSaveContactAction = (): ISpatialComponentActionData => {
	return {
		type: IActionCategory.saveContact,
		name: '',
		phone: '',
		company: '',
		email: '',
		website: '',
		phone2: '',
		phone3: '',
		street1: '',
		street2: '',
		city: '',
		county: '',
		zip: '',
		country: '',
		imgUrl: '',
	} as IBtnAndTxtActionData;
};

interface ISocial {
	provider: ISocialProvider;
	svg?: string;
	regex?: RegExp;
}

type ISocialsData = ISocial[];

export const socialsData: ISocialsData = [
	{
		provider: ISocialProvider.facebook,
		svg: FacebookIcon,
		regex: regex.facebook,
	},
	{
		provider: ISocialProvider.youtube,
		svg: YoutubeIcon,
		regex: regex.youtube,
	},
	{
		provider: ISocialProvider.instagram,
		svg: InstagramIcon,
		regex: regex.instagram,
	},
	{
		provider: ISocialProvider.reddit,
		svg: RedditIcon,
		regex: regex.reddit,
	},
	{
		provider: ISocialProvider.tiktok,
		svg: TiktokIcon,
		regex: regex.tiktok,
	},
	{
		provider: ISocialProvider.soundCloud,
		svg: SoundcloudIcon,
		regex: regex.soundCloud,
	},
	{
		provider: ISocialProvider.twitch,
		svg: TwitchIcon,
		regex: regex.twitch,
	},
	{
		provider: ISocialProvider.linkedIn,
		svg: LinkedinIcon,
		regex: regex.linkedIn,
	},
	{
		provider: ISocialProvider.vimeo,
		svg: VimeoIcon,
		regex: regex.vimeo,
	},
	{
		provider: ISocialProvider.spotify,
		svg: SpotifyIcon,
		regex: regex.spotify,
	},
	{
		provider: ISocialProvider.twitter,
		svg: TwitterIcon,
		regex: regex.twitter,
	},
	{
		provider: ISocialProvider.custom,
		svg: WebsiteIconSvg,
		regex: regex.custom,
	},
];

// Given an action which has a linkSocial property, will return the social network that is selected
export const getSelectedSocialNetwork = (action: ISpatialComponentActionData) => {
	if (Object.keys(action).length == 1 || action.type !== IActionCategory.linkSocial) return ISocialProvider.custom;
	const { type, ...rest } = action;
	return Object.keys(rest).reduce((acc, val) => {
		if (val) acc = val;
		return acc;
	}, ISocialProvider.custom) as ISocialProvider;
};

// Returns true / false if an entity already has one of linkPage, linkSocial, linkScene, dialNumber, saveContact, composeEmail actions
export const componentHasBlockingActionForTrigger = (component: ISpatialComponentUnion | ISceneComp, triggerType: ITriggerTypes) => {
	if (!component.actions || !component.actions[triggerType] || !component.actions[triggerType]?.length) return false;
	if (!component.actions) return false;
	for (let i = 0; i < component.actions[triggerType]!.length; i++) {
		if (
			[IActionCategory.linkPage, IActionCategory.linkScene, IActionCategory.linkSocial, IActionCategory.composeEmail, IActionCategory.dialNumber, IActionCategory.saveContact].includes(
				component.actions[triggerType]![i].type
			)
		) {
			return true;
		}
	}
	return false;
};

// Returns true if entity already has one of animateModel, enlarge, playSound, playVideo
export const componentHasNonBlockingActionForTrigger = (component: ISpatialComponentUnion | ISceneComp, triggerType: ITriggerTypes) => {
	if (!component.actions || !component.actions[triggerType] || !component.actions[triggerType]?.length) return false;
	for (let i = 0; i < component.actions[triggerType]!.length; i++) {
		if (
			[IActionCategory.takePhoto, IActionCategory.animateModel, IActionCategory.animateParticle, IActionCategory.enlarge, IActionCategory.playSound, IActionCategory.playVideo].includes(
				component.actions[triggerType]![i].type
			)
		) {
			return true;
		}
	}
	return false;
};

export const componentHasActionForTrigger = (component: ISpatialComponentUnion | ISceneComp, action: IActionCategory, triggerType: ITriggerTypes) => {
	if (!component.actions || !component.actions[triggerType] || !component.actions[triggerType]?.length) return false;
	for (let i = 0; i < component.actions[triggerType]!.length; i++) {
		if (action === component.actions[triggerType]![i].type) return true;
	}
	return false;
};

export const getAllActionTargetIdsFromEntityForTrigger = (entity: ISpatialComponentUnion, triggerType: ITriggerTypes) => {
	if (!entity?.actions || !entity?.actions?.[triggerType] || !entity?.actions?.[triggerType]?.length) return [];
	let targetIds: string[] = [];
	for (let i = 0; i < entity.actions[triggerType]!.length; i++) {
		const action = entity.actions[triggerType]![i];
		if (
			action.type === IActionCategory.playVideo ||
			action.type === IActionCategory.toggleVisibility ||
			action.type === IActionCategory.animateModel ||
			action.type === IActionCategory.animateParticle
		) {
			targetIds = [...targetIds, ...action.targetIds];
		}
	}
	return targetIds;
};

export const getSocialIcon = (socialNetwork: ISocialProvider) => {
	switch (socialNetwork) {
		case ISocialProvider.facebook:
			return <Facebook16 />;
		case ISocialProvider.instagram:
			return <Instagram16 />;
		case ISocialProvider.linkedIn:
			return <LinkedIn16 />;
		case ISocialProvider.reddit:
			return <Reddit16 />;
		case ISocialProvider.soundCloud:
			return <SoundCloud16 />;
		case ISocialProvider.spotify:
			return <Spotify16 />;
		case ISocialProvider.tiktok:
			return <TikTok16 />;
		case ISocialProvider.twitch:
			return <Twitch16 />;
		case ISocialProvider.twitter:
			return <Twitter16 />;
		case ISocialProvider.vimeo:
			return <Vimeo16 />;
		case ISocialProvider.youtube:
			return <YouTube16 />;
		case ISocialProvider.noSocial:
		case ISocialProvider.custom:
			return <Globe16 />;
	}
	return <></>;
};

export const actionDetail = (action: ISpatialComponentActionData, entityTitles: { [id: string]: string }, sceneTitlesDict: { [id: string]: string }): JSX.Element => {
	switch (action.type) {
		case IActionCategory.scorm: {
			const eventTitle = action.event === IScormEventType.progress ? 'Progress' : 'Completion';
			return (
				<div>
					<span>{`Event: ${eventTitle}`}</span>
					{action.event === IScormEventType.progress && <span>Progress: {Math.floor(action.progress * 100)}%</span>}
				</div>
			)
		}
		case IActionCategory.dialNumber:
			return (
				<div>
					<span>Dial number: {action.tel}</span>
				</div>
			);
		case IActionCategory.takePhoto:
			return (
				<div>
					<span>A photo will be taken on tap</span>
				</div>
			);
		case IActionCategory.playSound:
			return (
				<div>
					<span>Sound: {action.title}</span>
					<span>Stop other media: {action.stopMedia ? 'Yes' : 'No'}</span>
					<span>Loop: {action.loop ? 'Yes' : 'No'}</span>
				</div>
			);
		case IActionCategory.composeEmail: {
			const textArray = action.body?.split('%0D%0A');
			return (
				<div>
					<span style={{ paddingBottom: '4px' }}>
						<Mail16 /> {action.recipient}
					</span>
					<span>
						<Star16 /> {action.subject}
					</span>
					<p style={{ paddingBottom: '4px', marginTop: '0px' }}>
						<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '188px', display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
							<Message16 style={{ marginRight: '8px', marginBottom: '6px' }} />
							<span>{textArray[0]}</span>
						</span>
						{textArray.slice(0, 3).map((str, i) => {
							if (i === 0) return null;
							return (
								<span key={i} style={{ marginLeft: '22px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '168px', marginTop: '0px' }}>
									{str}
								</span>
							);
						})}
						{textArray.length > 3 && <span style={{ marginLeft: '20px', marginTop: '-4px' }}>...</span>}
					</p>
				</div>
			);
		}
		case IActionCategory.animateModel: {
			const modelId = action.targetIds[0];
			return (
				<div>
					<span>Model: {entityTitles[modelId]}</span>
					<span>Animation: {action.animationName}</span>
					<span>Loop: {action.animationRepetitions ? action.animationRepetitions : 'Forever'}</span>
					{action.isClampWhenFinished && <span>Finish: hold last frame</span>}
				</div>
			);
		}
		case IActionCategory.animateParticle: {
			const { duration = null, behaviour, targetIds } = action;
			const emitterId = targetIds[0];
			return (
				<div>
					<span>{`Particle: ${entityTitles[emitterId]}`}</span>
					<span>{`Action: ${behaviour.charAt(0).toUpperCase() + behaviour.slice(1)}`}</span>
					{behaviour !== 'stop' && <span>{`Particle duration: ${duration === null ? 'Forever' : `${duration} s`}`}</span>}
				</div>
			);
		}
		case IActionCategory.toggleVisibility: {
			const { behaviour, targetIds } = action;
			const entityId = targetIds[0];
			return (
				<div>
					<span>{`Object: ${entityTitles[entityId]}`}</span>
					<span>{`Action: ${behaviour.charAt(0).toUpperCase() + behaviour.slice(1)}`}</span>
				</div>
			);
		}
		case IActionCategory.linkPage: {
			const url = action.pageUrl;
			return (
				<div>
					<span>
						<a target="_blank" href={addUrlScheme(url)} rel="noreferrer">
							{url}
						</a>
					</span>
				</div>
			);
		}
		case IActionCategory.linkScene: {
			const sceneId = action.sceneId!;
			return (
				<div>
					<span>Scene: {sceneTitlesDict[sceneId]}</span>
				</div>
			);
		}
		case IActionCategory.playVideo:
			return (
				<div>
					<span>Video: {entityTitles[action.targetIds[0]]}</span>
				</div>
			);
		case IActionCategory.enlarge:
			return (
				<div>
					<span>The image will enlarge on tap</span>
				</div>
			);
		case IActionCategory.linkSocial: {
			const selectedSocialNetwork = getSelectedSocialNetwork(action);
			if (selectedSocialNetwork === ISocialProvider.custom) break;
			if (!selectedSocialNetwork) break;
			const socialActionValue = action[selectedSocialNetwork];
			if (!socialActionValue) break;
			const username = getUsernameFromSocialUrl(socialActionValue);
			const socialIcon = getSocialIcon(selectedSocialNetwork);
			return (
				<div>
					<span style={{ paddingBottom: '4px' }}>
						{socialIcon}{' '}
						<a href={action[selectedSocialNetwork]} target="_blank" rel="noreferrer">
							{username || action[selectedSocialNetwork]}
						</a>
					</span>
				</div>
			);
		}
		case IActionCategory.saveContact: {
			const { name, phone, email, type, ...rest } = action;
			const fields = Object.keys(rest);
			let hasMoreValues = false;
			for (let i = 0; i < fields.length; i++) {
				const field = fields[i];
				if (action[field]) hasMoreValues = true;
			}

			return (
				<div>
					<span style={{ paddingBottom: '4px' }}>
						<User16 /> {action?.name}
					</span>
					<span style={{ paddingBottom: '4px' }}>
						<Phone16 /> {action?.phone}
					</span>
					{!!action?.email && (
						<span style={{ paddingBottom: '4px' }}>
							<Mail16 /> {action?.email}
						</span>
					)}
					{hasMoreValues && (
						<span style={{ paddingBottom: '4px' }}>
							<Plus16 />
							additional fields
						</span>
					)}
				</div>
			);
		}
	}
	return <></>;
};

const parseSocialUrl = (url: string) => {
	let isValid = false;
	for (let i = 0; i < socialsData.length; i++) {
		const { regex, provider: social } = socialsData[i];
		if (regex && !!url.match(regex)) {
			isValid = true;
		}
	}
	return { isValid };
};

export const validateAction = (action: IBtnAndTxtActionData | null): IActionErrorUnion => {
	if (!action) return null;
	switch (action.type) {
		case IActionCategory.composeEmail: {
			const { isValid } = parseEmailAddress(action.recipient);
			if (isValid) return null;
			return { recipient: ACTION_ERROR_MESSAGES.invalidEmail };
		}

		case IActionCategory.dialNumber: {
			const { isValid } = parsePhoneNumber(action.tel);
			if (!action.tel) return { tel: ACTION_ERROR_MESSAGES.missingPhoneNumber };
			if (isValid) return null;
			return { tel: ACTION_ERROR_MESSAGES.invalidPhoneNumber };
		}

		case IActionCategory.linkPage: {
			const isValid = true; //parseCustomUrl(action.pageUrl);
			if (isValid) return null;
			return { pageUrl: ACTION_ERROR_MESSAGES.invalidWebsite };
		}

		case IActionCategory.saveContact: {
			const errors: ISaveContactActionErrors = {};

			// obligatory inputs
			if (!action.name) {
				errors.name = ACTION_ERROR_MESSAGES.missingName;
			}

			if (!action.phone) {
				errors.phone = ACTION_ERROR_MESSAGES.missingPhoneNumber;
			} else if (!parsePhoneNumber(action.phone).isValid) {
				errors.phone = ACTION_ERROR_MESSAGES.invalidPhoneNumber;
			}

			// optional inputs
			if (action.phone2 && !parsePhoneNumber(action.phone2).isValid) {
				errors.phone2 = ACTION_ERROR_MESSAGES.invalidPhoneNumber;
			}

			if (action.phone3 && !parsePhoneNumber(action.phone3).isValid) {
				errors.phone3 = ACTION_ERROR_MESSAGES.invalidPhoneNumber;
			}

			if (action.email && !parseEmailAddress(action.email).isValid) {
				errors.email = ACTION_ERROR_MESSAGES.invalidEmail;
			}

			if (action.website && !parseCustomUrl(action.website).isValid) {
				errors.website = ACTION_ERROR_MESSAGES.invalidWebsite;
			}

			return Object.keys(errors).length > 0 ? errors : null;
		}

		case IActionCategory.linkSocial: {
			const socialNetwork = getSelectedSocialNetwork(action);
			if (socialNetwork) {
				const url = action[socialNetwork];
				// No URL entered
				if (!url?.length) {
					const error: ISocialLinkActionErrors = {
						invalidProvider: ACTION_ERROR_MESSAGES.missingSocial,
					};
					return error;
				}
				// Not a valid URL for the given social network
				if (!parseSocialUrl(url).isValid) {
					if (socialNetwork !== ISocialProvider.custom) {
						const error: ISocialLinkActionErrors = {
							invalidProvider: ACTION_ERROR_MESSAGES.invalidSocial,
						};
						return error;
					} else {
						// "Custom" so it's actually an invalid URL, not invalid social URL
						const error: ISocialLinkActionErrors = {
							invalidProvider: ACTION_ERROR_MESSAGES.invalidWebsite,
						};
						return error;
					}
				}
				return null;
			}
		}
	}
	return null;
};

export const toggleVisibilityEntitySort = (componentsById: IComponentsById) => {
	return (a: string, b: string) => {
		const componentA = componentsById[a];
		const componentB = componentsById[b];
		const aTypeWeight = getComponentTypeSortWeight(componentA.type);
		const bTypeWeight = getComponentTypeSortWeight(componentB.type);
		if (aTypeWeight === bTypeWeight && aTypeWeight > 0) {
			if (isAbstractComponent(componentA) || isAbstractComponent(componentB)) return 0;
			return componentA.title.localeCompare(componentB.title);
		} else {
			return aTypeWeight - bTypeWeight;
		}
	};
};

const getComponentTypeSortWeight = (componentType: IComponentType) => {
	switch (componentType) {
		case IComponentType.Button:
			return 1;
		case IComponentType.Text:
			return 2;
		case IComponentType.Image:
			return 3;
		case IComponentType.Video:
			return 4;
		case IComponentType.Model3d:
			return 5;
		case IComponentType.Emitter:
			return 6;
	}
	return 0;
};
