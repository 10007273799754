import { IFsFileType } from "@r3f-component-data-structure";
import { FsImage } from "./image";

export class FsImageTarget extends FsImage {
    type: IFsFileType.ImageTarget = IFsFileType.ImageTarget
    protected _filename = 'img'
    private _zpt: Uint8Array | undefined

    async getZpt() {
        if (typeof this._zpt !== 'undefined') return this._zpt

        const url = new URL(this.baseUrl)
        url.pathname = `${url.pathname}/zpt`
        const blob = await this.fs.getFileBlob(url.toString())
        this._zpt = new Uint8Array(await blob.arrayBuffer())
        return this._zpt
    }

    async getFilesForBundler() {
        const [blob, zpt] = await Promise.all([ this.getBlob(), this.getZpt() ])
        return [
            {
                path: `fs/${this.id}/${this._filename}`,
                bin: new Uint8Array(await blob.arrayBuffer())
            },
            {
                path: `fs/${this.id}/zpt`,
                bin: zpt
            }
        ]
    }
}
