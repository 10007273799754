import {image_target_type_t} from '@zappar/zappar-cv';
import { IAreaTypes, ITuple3, IUnitTypes } from './general';
import { IGrabAndGoOptions } from './grabAndGo';

export enum ITrackingTypes {
	image = 'image',
	face = 'face',
	world = 'world',
	noTrackingScreen = 'noTrackingScreen',
	content360 = 'content360'
}

export enum IImageTrackingOrientation {
	upright = 'upright',
	flat = 'flat'
}

export interface ITargetAreaData {
	heightInMm?: number;
	targetArea?: IAreaTypes;
}

export interface ITrackingImageData {
	filestoreId?: string;
	orientation: IImageTrackingOrientation;
	units: IUnitTypes;
	aspectRatio: number;
	opacity: number;
	targetAreaData: ITargetAreaData;
	scale?: ITuple3;
	targetType?: image_target_type_t;
	targetImageHeight?: number;
	targetImageWidth?: number;
	normalizationFactor?: number;
	topRadius?: number;
	bottomRadius?: number;
	sideLength?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWorldTrackingData {
}

export type ITrackingContentDocByTrackingType = Partial<{
	grabAndGo?: IGrabAndGoOptions;
	[ITrackingTypes.image]: ITrackingImageData;
	[ITrackingTypes.world]: IWorldTrackingData;
}>

