export enum IFsFileType {
    Unknown = 'unknown',
    Image = 'image',
    Image360 = 'image360',
    Video = 'video',
    Video360 = 'video360',
    Audio = 'audio',
    Model3d = 'model3d',
    ImageTarget = 'imagetarget'
}

// Filestore data stored in the content doc
export interface IFsFileData {
    id: string
    type: IFsFileType
    name: string
}
