import React, { memo, Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IDesignerState } from '@/../typings';
import { store } from '@/store';
import { updateProjectScreenshot } from '@/utils';
import Background360 from '../../r3f-components/components/Background360';
import Dummy360Content from './Dummy360Content';
import { filestore, FsImage360, FsVideo360 } from '@/filestore';
import { IFsFileType, ISceneComp } from '@r3f-component-data-structure';
import { Texture } from 'three';

type videoStatus = "error" | "pending" | "ready" | "complete" | undefined
type Background360Media = { imageTexture?: Texture, videoUrl?: string }

const Background360Placeholder: React.FunctionComponent = () => {
    const activeSceneId = useSelector((state: IDesignerState) => state.userReducer.activeSceneId);
    const componentsById = useSelector((state: IDesignerState) => state.contentReducer?.contentDoc?.componentsById || null);
    const content360Data = activeSceneId ? (componentsById[activeSceneId] as ISceneComp)?.content360 : undefined;

    const [media, setMedia] = useState<Background360Media>();
    const [videoStatus, setVideoStatus] = useState<videoStatus>();

    useEffect(() => {
        if (typeof content360Data?.filestoreId === 'undefined') return;
        const fsFile = filestore.load<FsImage360 | FsVideo360>(content360Data.filestoreId)
        if (fsFile.type === IFsFileType.Image360) {
            fsFile.getTexture().then(tx => {
                setMedia({ imageTexture: tx, videoUrl: undefined })
            })
        } else if (fsFile.type === IFsFileType.Video360) {
            setMedia({ imageTexture: undefined, videoUrl: fsFile.getPlaylistUrl()})
            fsFile.getProgressData().then(({ status }) => { setVideoStatus(status) })
        }
    }, [content360Data?.filestoreId])

    const onTextureReady = useCallback(() => {
        const projectId = store?.getState?.()?.userReducer?.project?.id;
        if (projectId) {
            setTimeout(() => {
                updateProjectScreenshot(projectId, false)
            }, 1500)
        }
    }, [])

    return <Suspense fallback={null}>
        {!media ? 
            <Dummy360Content radius={60} /> :
            <Background360
                radius={60}
                enableVideoPlay={false}
                sceneId={activeSceneId || ''} 
                enable360Environment={Boolean(content360Data?.filestoreId)}
                environmentRenderPriority={-5}
                videoStatus={videoStatus}
                onTextureReady={onTextureReady}
                media={media}
            />
        }
    </Suspense>
}
export default memo(Background360Placeholder);