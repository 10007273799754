import { IFile, IFileAudio, IFileImage, IFileImage360, IFileModel3D, IFileStreamingVideo, IFileVideo360, IProgressStatus } from '@zml-client';
import { ILoadedMediaTypes } from '../../../store/actions';
import { IUploadIndexToFileDict } from './utils';
import { Dispatch } from 'redux';

export interface IFileIdDict {
	[id: string]: string;
}

export interface IOnErrorData {
	id: string;
	fileName: string;
	type: IProgressStatus;
	mediaType: ILoadedMediaTypes;
	status?: number;
	desc?: string;
}

export interface IOnProgressData {
	id: string;
	fileName: string;
	type: IProgressStatus;
	perc: number;
}

export interface IOnCompletionData {
	id: string;
	fileName: string;
	data: IFile;
}

export interface IOnAnalysisCompletionData {
	id: string;
	fileName: string;
	// data: ITrackingImageData;
}

export type IOnAnalysisProgressData = IOnProgressData;

export enum IUploadFileTypes {
	model_bin = 'application/octet-stream',
	model_zip = 'application/zip',
	model_gltf = 'model/gltf+json',
	model_glb = 'model/gltf-binary',
	model_glb_ext = '.glb',
	model_ext_fallback = '.glb,.gltf,.zip,.bin,.png,.jpg',
	video_all = 'video/*',
	video_quicktime = 'video/quicktime',
	video_avi = 'video/x-msvideo',
	video_mp4 = 'video/mp4',
	video_mpeg = 'video/mpeg',
	video_3gp = 'video/3gpp',
	video_flv = 'video/x-flv',
	video_webm = 'video/webm',
	video_ext_fallback = '.webm,.mkv,.mov', // these are used in the "accept" attribute of <input type="file"> because, for example, Safari doesn't recognise webm or mkv mime types
	image_jpeg = 'image/jpeg',
	image_png = 'image/png',
	image_ext_fallback = '.jpeg,.jpg,.png',
	audio_wav = 'audio/wav',
	audio_mpeg = 'audio/mpeg',
	audio_ogg = 'audio/ogg',
	audio_m4a = 'audio/m4a',
	audio_ext_fallback = '.wav,.ogg,.mp3,.mp4'
}

export enum IErrorText {
	formatNotSupported = 'format not supported',
	gltfMediaLibrary = 'please upload via media library',
	fileDuplicate = 'file duplicate',
}

export type IOnDropCb = (fileDict: IUploadIndexToFileDict) => any;
export type IOnProgressCb = (p: IOnProgressData) => any;
export type IOnCompletionCb = (c: IOnCompletionData) => any;
export type IOnErrorCb = (e: IOnErrorData) => any;
export type IOnAnalysisProgressCb = (ap: IOnAnalysisProgressData) => any;
export type IOnAnalysisCompletionCb = (ac: IOnAnalysisCompletionData) => any;

interface IUploadFilesOfTypeArgs {
	files: File[],
	allowedFileTypes: IUploadFileTypes[],
	fileType: ILoadedMediaTypes,
	dispatch: Dispatch,
	onError?: IOnErrorCb,
	onCompletion?: IOnCompletionCb,
	onProgress?: IOnProgressCb,
	onDrop?: IOnDropCb,
	is360Content?: boolean
}


interface IUploadFilesOfMultipleTypesArgs extends Omit<IUploadFilesOfTypeArgs, "allowedFileTypes"> {
	allowedFileTypes: IUploadFileTypes[][];
	fileTypes: ILoadedMediaTypes[];
}

export type IUploadResponseFileTypes = (IFileImage | IFileStreamingVideo | IFileAudio | IFileModel3D | IFileImage360 | IFileVideo360)[];

export type IUploadFilesOfType = (args: IUploadFilesOfTypeArgs) => Promise<IUploadResponseFileTypes | null>;
export type IUploadFilesOfMultipleTypes = (args: IUploadFilesOfMultipleTypesArgs) => Promise<IUploadResponseFileTypes | null>;

interface ICreateUploadIndexToFileDictArgs {
	files: File[];
	allowedFileTypes: string[];
	onError: IOnErrorCb;
	fileType: ILoadedMediaTypes;
	dispatch: Dispatch<any>;
	onProgress?: IOnProgressCb; 
	is360Content?: boolean;
}

interface ICreateUploadIndexToFileDictReturn {
	uploadIndexToFileDict: IUploadIndexToFileDict;
	imageFilesArray: File[];
	videoFilesArray: File[];
	audioFilesArray: File[]; 
	model3dFilesArray: File[];
	image360FilesArray: File[]; 
	video360FilesArray: File[];
}

export type ICreateUploadIndexToFileDict = (args: ICreateUploadIndexToFileDictArgs) => ICreateUploadIndexToFileDictReturn;

interface IUploadMediaArgs {
	uploadIndexToFileDict: IUploadIndexToFileDict,
	imageFilesArray: File[],
	videoFilesArray: File[],
	audioFilesArray: File[],
	model3dFilesArray: File[],
	image360FilesArray: File[]; 
	video360FilesArray: File[];
	fileType: ILoadedMediaTypes,
	onError: IOnErrorCb,
	onCompletion: IOnCompletionCb,
	onProgress: IOnProgressCb
}

export type IUploadMedia = (args: IUploadMediaArgs) => Promise<IUploadResponseFileTypes | null>;