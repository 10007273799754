import { Texture } from "three";
import { IFsFileType } from "@r3f-component-data-structure";
import { FsBaseFile } from "./base";

export class FsImage extends FsBaseFile {
    type: IFsFileType.Image | IFsFileType.Image360 | IFsFileType.ImageTarget = IFsFileType.Image
    protected _filename: string = 'img'
    protected _cdnUrl: string
    private _texture: Texture | undefined

    constructor(fileId: string, projectId: string, name: string) {
        super(fileId, projectId, name)
        const url = new URL(this.baseUrl)
        url.pathname = `${url.pathname}/${this._filename}`
        this._cdnUrl = url.toString()
    }

    async getUrl() {
        return this.fs.getObjectUrl(this._cdnUrl, await this.getBlob())
    }

    async getBlob() {
        return this.fs.getFileBlob(this._cdnUrl)
    }

    async createImageBitmap() {
        return createImageBitmap(await this.getBlob())
    }

    async getAspectRatio() {
        const img = await this.createImageBitmap()
        if (img.height === 0) return 1
        return img.width / img.height
    }

    async getTexture() {
        if (typeof this._texture === 'undefined') {
            const blob = await this.getBlob()
            this._texture = new Texture()
            // this._texture.flipY = false
            this._texture.image = await createImageBitmap(blob)
            this._texture.needsUpdate = true
        }
        return this._texture
    }

    async getFilesForBundler() {
        const blob = await this.getBlob()
        return [{
            path: `fs/${this.id}/${this._filename}`,
            bin: new Uint8Array(await blob.arrayBuffer())
        }]
    }
}
