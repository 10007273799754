import { Euler } from '@react-three/fiber';
import { image_target_type_t } from '@zappar/zappar-cv';
import { Environment } from '@zw-api-client';
import React, { useRef } from 'react';
import { EmptyObject } from 'redux';
import { MathUtils, Vector3 } from 'three';
import { IContentReducer, IDomIdSelectors, IUserState } from '../../typings';
import { BASIC_BUTTON_TEXT } from '../components/dom/menus/EntityMenu/SubMenu/ButtonSelection/DefaultBtnGrid/DefaultBtnGrid';
import { ARRenderer } from '../components/r3f/EditorCanvas/EditorCanvas';
import withButtonBehaviour from '../components/r3f/HOC/withButtonBehaviour';
import withEmitterBehaviour from '../components/r3f/HOC/withEmitterBehaviour';
import withImageBehaviour from '../components/r3f/HOC/withImageBehaviour';
import withModel3dBehaviour from '../components/r3f/HOC/withModel3dBehaviour';
import withText3dBehaviour from '../components/r3f/HOC/withText3dBehaviour';
import withTextBehaviour from '../components/r3f/HOC/withTextBehaviour';
import withVideoBehaviour from '../components/r3f/HOC/withVideoBehaviour';

import { Button, Emitter, Image as R3fImage, Model3d, Text, Text3d, Video } from '../components/r3f/r3f-components';
import {
	IAreaTypes,
	IButton,
	IButtonBaseState,
	IButtonSubCategory,
	IComponentType,
	IComponentUnion,
	ICurveComponentUnion,
	IEmitterCategory,
	IFaceLandmark,
	IFaceLandmarkGroup,
	IFontTypes,
	IImage,
	IModel3d,
	IModel3dAnimation,
	ISceneComp,
	IScreenAnchorGroup,
	IScreenAnchorPositionType,
	IScreenComponentUnion,
	IScreenContent,
	ISocialOptions,
	ISocialProvider,
	ISpatialComponentUnion,
	IText,
	IText3d,
	ITextAlignment,
	ITrackingTypes,
	ITuple2,
	ITuple3,
	IUnitTypes,
	IVector4,
	IVideo,
} from '@r3f-component-data-structure';
import { getFaceTrackedComponentIds } from '../components/r3f/r3f-components/hooks/useGetScreenRelativeComponentIds';
import { FACE_LANDMARK_DATA } from '../components/r3f/r3f-components/utils/constants';
import { getFaceLandmarkPositionsByHeadbustType } from '../components/r3f/r3f-components/utils/face-tracking';
import { diffBySingleCharacter, isAbstractComponent, isCurveComponent } from '../components/r3f/r3f-components/utils/general';
import { getScreenContentIdForScene } from '../components/r3f/r3f-components/utils/pure';
import { screenRelativeRenderer } from '../components/r3f/ScreenRelativeCanvas/ScreenRelativeCanvas';
import { IOnPasteCopiedEntities_Global_Payload, ISceneSnapshotDict } from '../store/actions';
import { zwClient } from '../sync/zapworks';
import {
	DEFAULT_BUTTON_TITLE,
	DEFAULT_FACE_LANDMARK,
	DEFAULT_SCREEN_RELATIVE_ANCHOR_POSITION,
	DEFAULT_TEXT_TITLE,
	EMITTER_CATEGORY_TEXTURE_DICT,
	EMITTER_TEXTURE_DICT,
	HEAD_MESH_DIMENSIONS,
	IFontStyles,
	IMAGE_CANVAS_SIZE_FACTOR,
	IText3dTextures,
	SCREEN_RELATIVE_CANVAS_SIZE_FACTOR,
	SCREEN_RELATIVE_DEVICES,
	SNAPSHOT_PX_HEIGHT,
	SNAPSHOT_TTL_MS,
	SR_AR_RATIO,
	VIDEO_CANVAS_SIZE_FACTOR,
} from './constants';
import { getComponentParentIdById } from './pure';
import { getEntityDropTitle, getSREntityScale } from './pure/get';
import { getEntityDropCoordinates } from './pure/getEntityDropCoordinates';
import { socialRegex } from './social';
import { filestore, FsImage, FsModel3D, FsVideo } from '@/filestore';

export const sceneHasScreenContent = (
	componentsById: {
		[id: string]: IComponentUnion;
	},
	sceneId: string
) => {
	const sceneChildren = (componentsById[sceneId] as ISceneComp)?.children;
	const sceneScreenContentAbstractComponent = sceneChildren.filter((id) => componentsById[id].type === IComponentType.ScreenContent)[0];
	const anchorGroups = (componentsById[sceneScreenContentAbstractComponent] as IScreenContent).children;
	for (let i = 0; i < anchorGroups.length; i++) {
		if ((componentsById[anchorGroups[i]] as IScreenAnchorGroup).children.length > 0) return true;
	}
	return false;
};

export const getInitialCamera = (isFlatOrientation: boolean, trackingType: ITrackingTypes, isCurvedImageTrackedScene = false) => {
	// Image tracked, upright
	let target = [0, 0, 0] as ITuple3;
	let position = [0, 0, 7] as ITuple3;

	// Flat, Image Tracked
	if (isFlatOrientation && trackingType === ITrackingTypes.image) {
		position = [0, 4.5, 9.5];
		target = [0, 1, 0];
	}

	// Curved, Image Tracked
	if (isCurvedImageTrackedScene && trackingType === ITrackingTypes.image) {
		position = [0, 0, 4];
		target = [0, 0, 0];
	}

	// Curved, Image Tracked, Flat orientation
	if (isCurvedImageTrackedScene && trackingType === ITrackingTypes.image && isFlatOrientation) {
		position = [4, 0, 0];
		target = [0, 0, 0];
	}

	// World Tracked
	if (trackingType === ITrackingTypes.world) {
		position = [0, 7, 18];
		target = [0, 2, 0];
	}

	if (trackingType === ITrackingTypes.content360) {
		position = [0, 0, 0.01];
		target = [0, 0, 0];
	}

	return {
		target,
		position,
	};
};

export const getEditorRotation = (isFlatOrientation: boolean, trackingType: ITrackingTypes) => {
	return (isFlatOrientation && trackingType !== ITrackingTypes.world && trackingType !== ITrackingTypes.face && trackingType !== ITrackingTypes.content360 ? [-Math.PI / 2, 0, 0] : [0, 0, 0]) as Euler;
};

export const isNull = (num: null | number) => num === null;

// True if t2 is not equal to t1
export const iTuple3HasChanged = (t1: ITuple3, t2: ITuple3): boolean => {
	return !(t1[0] === t2[0] && t1[1] === t2[1] && t1[2] === t2[2]);
};

// Converts a Vector3 to ITuple3 and rounds to dp decimal places
export const roundedVector = (v: Vector3, dp: number): ITuple3 => {
	return [v.x, v.y, v.z].map((coord) => Number(coord.toFixed(dp))) as ITuple3;
};

export const calcBooleanMarkerIndexVariables = (markerIndexPressed: number) => {
	return {
		hMiddleMarkerPressed: markerIndexPressed === 1 || markerIndexPressed === 5,
		vMiddleMarkerPressed: markerIndexPressed === 3 || markerIndexPressed === 7,
		topSideMarkerPressed: markerIndexPressed < 3,
		rightSideMarkerPressed: markerIndexPressed > 1 && markerIndexPressed < 5,
		bottomSideMarkerPressed: markerIndexPressed > 3 && markerIndexPressed < 7,
		leftSideMarkerPressed: markerIndexPressed === 0 || markerIndexPressed > 5,
		topCornerPressed: markerIndexPressed === 0 || markerIndexPressed === 2,
		bottomCornerPressed: markerIndexPressed === 4 || markerIndexPressed === 6,
	};
};

export const replaceCharInStringAt = (i: number, str: string, rpl: string) => {
	return str.substr(0, i) + rpl + str.substr(i + rpl.length);
};

export const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

interface ISceneSnapshotData {
	value: string;
	expiry: number;
}

// Falls back to custom
export const getMatchingSocialNetwork = (val: string) => {
	const socials = Object.keys(socialRegex);
	for (let i = 0; i < socials.length; i++) {
		const provider = socials[i];
		const regex = socialRegex[provider as keyof typeof socialRegex];
		if (val.match(regex)) return provider as ISocialProvider;
	}
	return ISocialProvider.custom;
};

export const getUsernameFromSocialUrl = (url: string) => {
	if (!url) return '';
	const socials = Object.keys(socialRegex);
	let userName = '';

	for (let i = 0; i < socials.length; i++) {
		const provider = socials[i];
		const regex = socialRegex[provider as keyof typeof socialRegex];
		const res = url.match(regex);
		if (res) {
			userName = res[1];
			return userName;
		}
	}
	return userName;
};

export const generateSocialUrl = (provider: ISocialProvider, userName: string) => {
	let generatedUrl = '';
	const user = userName || '';
	switch (provider) {
		case ISocialProvider.facebook:
			generatedUrl = `https://www.facebook.com/${user}`;
			break;
		case ISocialProvider.youtube:
			generatedUrl = `https://www.youtube.com/user/${user}`;
			break;
		case ISocialProvider.instagram:
			generatedUrl = `https://www.instagram.com/${user}`;
			break;
		case ISocialProvider.reddit:
			generatedUrl = `https://www.reddit.com/user/${user}`;
			break;
		case ISocialProvider.tiktok:
			generatedUrl = `https://www.tiktok.com/@${user}`;
			break;
		case ISocialProvider.soundCloud:
			generatedUrl = `https://soundcloud.com/${user}`;
			break;
		case ISocialProvider.twitch:
			generatedUrl = `https://www.twitch.tv/${user}`;
			break;
		case ISocialProvider.linkedIn:
			generatedUrl = `https://www.linkedin.com/in/${user}`;
			break;
		case ISocialProvider.vimeo:
			generatedUrl = `https://vimeo.com/${user}`;
			break;
		case ISocialProvider.spotify:
			generatedUrl = `https://open.spotify.com/user/${user}`;
			break;
		case ISocialProvider.twitter:
			generatedUrl = `https://www.twitter.com/${user}`;
			break;
		default:
			break;
	}
	return generatedUrl;
};

export const getSocialUrlForProvider = (socialOptions: ISocialOptions, provider: ISocialProvider) => {
	switch (provider) {
		case ISocialProvider.facebook:
			return socialOptions.Facebook;
		case ISocialProvider.youtube:
			return socialOptions.Youtube;
		case ISocialProvider.instagram:
			return socialOptions.Instagram;
		case ISocialProvider.reddit:
			return socialOptions.Reddit;
		case ISocialProvider.tiktok:
			return socialOptions.TikTok;
		case ISocialProvider.soundCloud:
			return socialOptions.SoundCloud;
		case ISocialProvider.twitch:
			return socialOptions.Twitch;
		case ISocialProvider.linkedIn:
			return socialOptions.LinkedIn;
		case ISocialProvider.vimeo:
			return socialOptions.Vimeo;
		case ISocialProvider.spotify:
			return socialOptions.Spotify;
		case ISocialProvider.twitter:
			return socialOptions.Twitter;
		case ISocialProvider.custom:
			return socialOptions.custom;
		default:
			break;
	}
};

// export const parseCustomUrlOld = (pageUrl: string) => {
// 	const res = pageUrl.match(
// 		/(?:(?:http|https):\/\/)?(?:www\.)?((?:[\w\-]{1,})\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%\-_\+.~#?&\/=]*)/
// 	) as string[] | null;
// 	let isValid = !!res;
// 	if (res) isValid = res[0].length === pageUrl.length;
// 	return {
// 		isValid,
// 		domain: res ? res[1] : null,
// 	};
// };

export const parseCustomUrl = (pageUrl: string) => {
	const res = pageUrl.match(
		/^(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|works|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?$/i // eslint-disable-line
	) as string[] | null;
	return { isValid: !!res };
};

// export const parseSceneTitle = (title: string) => { // Not in use
// 	return title.match(/([\S]{1,}\s{1}(?:\[{1}[\d]{1,}\]){0,})\[{1}([\d]{1,})\]{1}$/);
// };

export const parsePhoneNumber = (numberString: string) => {
	const res = numberString.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/) as string[] | null; // eslint-disable-line
	const isValid = !!res;
	return { isValid };
};

// Email validator that adheres directly to the specification for email address naming.
// It allows for everything from ipaddress and country-code domains, to very rare characters
// in the username.

export const parseEmailAddress = (email: string) => {
	const res = email.match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/) as string[] | null; // eslint-disable-line
	const isValid = !!res;
	return { isValid };
};

function setLocalStorageWithExpiry(key: string, value: string, ttlInMs: number): void {
	const now = new Date();
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item: ISceneSnapshotData = {
		value: value,
		expiry: now.getTime() + ttlInMs,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export const updateLStorageWithSnapshot = (activeSceneId: string, projectId: string, useScreenRelativeCanvas = false) => {
	if (!activeSceneId) return;
	const domId = useScreenRelativeCanvas ? IDomIdSelectors.screenRelativeCanvas : IDomIdSelectors.zapparCanvas;
	const canvasWrapperDiv = document?.getElementById?.(domId);
	const canvas = canvasWrapperDiv?.getElementsByTagName?.('canvas')?.item(0);
	if (!canvas) return;
	const canvasRatio = canvas.width / canvas.height;
	const sizeAdjCanvas = document.createElement('canvas');
	sizeAdjCanvas.width = SNAPSHOT_PX_HEIGHT * canvasRatio;
	sizeAdjCanvas.height = SNAPSHOT_PX_HEIGHT;
	const sizeAdjCanvasCtx = sizeAdjCanvas.getContext('2d');
	if (!sizeAdjCanvasCtx) return;
	sizeAdjCanvasCtx.imageSmoothingEnabled = false;
	sizeAdjCanvasCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, sizeAdjCanvas.width, sizeAdjCanvas.height);

	const snapshotBase64 = sizeAdjCanvas.toDataURL('image/png');
	setLocalStorageWithExpiry(`${projectId}_${activeSceneId}_zapparScene`, snapshotBase64, SNAPSHOT_TTL_MS);
	return { [activeSceneId]: snapshotBase64 };
};

function getSnapshotAndUpdateExpiry(key: string, ttlInMs: number) {
	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) return null;
	const item = JSON.parse(itemStr) as ISceneSnapshotData;
	const now = new Date();
	item.expiry = now.getTime() + ttlInMs;
	localStorage.setItem(key, JSON.stringify(item));
	return item.value;
}

export const removeExpiredSceneSnapshots = () => {
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (!key) continue;
		const isZapparScene = key.split('_')[2] === 'zapparScene';
		if (!isZapparScene) continue;
		const itemStr = localStorage.getItem(key);
		if (!itemStr) continue;
		const item = JSON.parse(itemStr);
		const now = new Date();
		if (now.getTime() > item.expiry) localStorage.removeItem(key);
	}
};

export const getSnapshotDictFromLStorage = (localStorage: Storage, currentProjectId: string) => {
	const scenesSnapshotDict: ISceneSnapshotDict = {};
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		if (!key) continue;
		const isZapparScene = key.split('_')[2] === 'zapparScene';
		if (!isZapparScene) continue;
		const projectId = key.split('_')[0];
		if (projectId !== currentProjectId) continue; //TODO: refactor
		const sceneId = key.split('_')[1];
		const dataUrl = getSnapshotAndUpdateExpiry(key, SNAPSHOT_TTL_MS)!;
		//const dataUrl = localStorage.getItem(key);
		if (!dataUrl) continue;
		scenesSnapshotDict[sceneId] = dataUrl;
	}
	return scenesSnapshotDict;
};

export const removeSnapshotFromLStorage = (localStorage: Storage, currentProjectId: string, sceneId: string) => {
	localStorage.removeItem(`${currentProjectId}_${sceneId}_zapparScene`);
};

export const updateProjectScreenshot = (projectId: string, isNoTrackingScene = false) => {
	const srCanvas = screenRelativeRenderer?.domElement;
	let canvas = ARRenderer?.domElement;
	if (isNoTrackingScene && !!srCanvas) canvas = srCanvas;
	if (typeof canvas !== 'undefined') {
		canvas.toBlob(async blob => {
			if (blob) {
				try {
					await zwClient.postProjectScreenshot(projectId, blob)
				} catch(err) {
					console.warn('Unable to submit project screenshot:', err)
				}
			}
		}, 'image/jpeg', 0.6)
	}
};

interface IObject {
	[key: string]: unknown;
}

export const checkIfObjectArrayPropsEqual = (props: string[], objArray: IObject[]) => {
	const propsToCheck: { [key: string]: boolean[] } = {};

	for (let i = 0; i < props.length; i++) {
		const property = props[i];
		const value = objArray[0][property];
		if (typeof value !== 'object' && typeof value !== 'undefined') propsToCheck[property] = [true];
		else if (value instanceof Array) propsToCheck[property] = value.map((_val) => true);
	}
	for (let i = 0; i < objArray.length - 1; i++) {
		const prevObj = objArray[i];
		const currObj = objArray[i + 1];
		for (const key in propsToCheck) {
			for (let i = 0; i < propsToCheck[key].length; i++) {
				propsToCheck[key][i] = prevObj[key]?.[i] === currObj[key]?.[i];
			}
		}
	}
	return propsToCheck;
};

export const addUrlToSocialParams = (url: string, socialData: ISocialOptions, provider: ISocialProvider) => {
	switch (provider) {
		case ISocialProvider.facebook:
			socialData.Facebook = url;
			break;
		case ISocialProvider.youtube:
			socialData.Youtube = url;
			break;
		case ISocialProvider.instagram:
			socialData.Instagram = url;
			break;
		case ISocialProvider.reddit:
			socialData.Reddit = url;
			break;
		case ISocialProvider.tiktok:
			socialData.TikTok = url;
			break;
		case ISocialProvider.soundCloud:
			socialData.SoundCloud = url;
			break;
		case ISocialProvider.twitch:
			socialData.Twitch = url;
			break;
		case ISocialProvider.linkedIn:
			socialData.LinkedIn = url;
			break;
		case ISocialProvider.vimeo:
			socialData.Vimeo = url;
			break;
		case ISocialProvider.spotify:
			socialData.Spotify = url;
			break;
		case ISocialProvider.twitter:
			socialData.Twitter = url;
			break;
		case ISocialProvider.custom:
			socialData.custom = url;
			break;
		default:
			break;
	}
};

export const getDimensionsForAreaTypeInMm: (a: IAreaTypes) => ITuple2 = (areaType: IAreaTypes) => {
	switch (areaType) {
		case IAreaTypes.a5:
			return [148, 210];
		case IAreaTypes.a4:
			return [210, 297];
		case IAreaTypes.a3:
			return [297, 420];
		case IAreaTypes.ltr:
			return [215.9, 279.4];
		case IAreaTypes.b5:
			return [176, 250];
		case IAreaTypes.b4:
			return [257, 364];
		case IAreaTypes.pstc:
			return [197.56, 101.6];
		case IAreaTypes.bsnc:
			return [88.9, 50.8];
		case IAreaTypes.pstr:
			return [457.2, 609.6];
		case IAreaTypes.blbrd:
			return [6096, 3084];
		case IAreaTypes.tbl:
			return [279.4, 431.8];
		case IAreaTypes.lghtBx:
			return [1187, 1750];
		default:
			return [210, 297];
	}
};

export const getAreaAspectRatioForArea = (a: IAreaTypes) => {
	const scaleInMm = getDimensionsForAreaTypeInMm(a);
	return scaleInMm[0] / scaleInMm[1];
};

export const getAreaTypeForAreaScaleInMm = (definedAreaTypes: IAreaTypes[], area: ITuple2) => {
	return (
		definedAreaTypes.filter((areaType) => {
			const areaTypeScaleInMm = getDimensionsForAreaTypeInMm(areaType);
			// console.log('areaTypeScaleInMm', areaTypeScaleInMm)
			// console.log('area', area)
			return areaTypeScaleInMm[0] === area[0] && areaTypeScaleInMm[1] === area[1];
		})[0] || IAreaTypes.custom
	);
};

export const convUnitToMm = (value: number, unit: IUnitTypes) => {
	switch (unit) {
		case IUnitTypes.coords:
			return 0.01;
		case IUnitTypes.mm:
			return 1 * value;
		case IUnitTypes.cm:
			return 10 * value;
		case IUnitTypes.inch:
			return 25.4 * value;
		case IUnitTypes.ft:
			return 304.8 * value;
		// case IUnitTypes.m:
		// 	return 1000 * value;
		default:
			return null;
	}
};

export const getInputStepByUnit = (unit: IUnitTypes): number => {
	switch (unit) {
		case IUnitTypes.coords:
			return 1;
		case IUnitTypes.cm:
			return 0.01;
		case IUnitTypes.mm:
			return 0.1;
		case IUnitTypes.ft:
			return 0.01 * 0.0328084;
		case IUnitTypes.inch:
			return 0.01 * 0.3937008;
		default:
			return 1;
	}
};

export const convMmToUnit = (value: number, unit: IUnitTypes) => {
	return value / (convUnitToMm(1, unit) ?? 1);
};

export const getProjectsUrl = () => {
	switch (zwClient.env) {
		case Environment.Local:
			return 'https://local.my.zap.works/projects';
		case Environment.Dev:
			return 'https://dev.my.zap.works/projects';
		case Environment.Staging:
			return 'https://staging.my.zap.works/projects';
		default:
			return 'https://my.zap.works/projects';
	}
};

export const getXYCoordsFromTransform = (transformString: string) => {
	return [transformString.split('(')[1].split(',')[0].split('px')[0], transformString.split('(')[1].split(',')[1].split('px')[0]];
};

export const getFontUrlFromDict = (fontType: IFontTypes, fontObjArray: { [fontName: string]: string }[]) => {
	const selFontObjArray = fontObjArray.filter((fontObj) => fontObj.name === fontType);
	if (!selFontObjArray.length) return null;
	return selFontObjArray[0].url;
};

export const loadFontsToDocument = (fontObjArray: { fontFamily: IFontTypes; url: string; desc: string }[]) => {
	const style = document.createElement('style');
	for (let i = 0; i < fontObjArray.length; i++) {
		const { url, fontFamily } = fontObjArray[i];
		// FontFace() has poor browser support: https://developer.mozilla.org/en-US/docs/Web/API/FontFace/FontFace
		// const font = new FontFace(fontFamily, `url(${url})`);
		// await font.load();
		// document.fonts.add(font);

		style.innerHTML =
			style.innerHTML +
			`@font-face {
			font-family: ${fontFamily};
			src: url(${url}) format("woff");
		}`;
	}
	document.head.appendChild(style);
};

export function moveArrayItemsByOffset<T>(renderSortedEntityIds: T[], selectedEntityIds: T[], offset: number): T[] {
	const _renderSortedEntityIds = [...renderSortedEntityIds];
	const renderSortedSelectedEntityIds = renderSortedEntityIds.filter((item) => selectedEntityIds.includes(item));

	if (offset > 0) renderSortedSelectedEntityIds.reverse();
	else _renderSortedEntityIds.reverse();

	for (let i = 0; i < renderSortedSelectedEntityIds.length; i++) {
		const selectedEntityId = renderSortedSelectedEntityIds[i];
		const selectedEntityIndex = _renderSortedEntityIds.indexOf(selectedEntityId);
		_renderSortedEntityIds.splice(selectedEntityIndex + Math.abs(offset) + 1, 0, selectedEntityId);
		_renderSortedEntityIds.splice(selectedEntityIndex, 1);
	}

	if (offset < 0) _renderSortedEntityIds.reverse();

	return _renderSortedEntityIds;
}

export const useIsDoubleClick = (ms = 400) => {
	const clickRef = useRef(0);
	const timeoutRef = useRef<NodeJS.Timeout>();

	return () => {
		clickRef.current++;
		if (clickRef.current === 1) {
			timeoutRef.current = setTimeout(() => (clickRef.current = 0), ms);
			return false;
		} else {
			timeoutRef.current && clearTimeout(timeoutRef.current);
			clickRef.current = 0;
			return true;
		}
	};
};

export const symmetricDifference = <T,>(setA: Set<T>, setB: Set<T>): Set<T> => {
	const _difference = new Set(setA);
	for (const elem of setB) {
		if (_difference.has(elem)) {
			_difference.delete(elem);
		} else {
			_difference.add(elem);
		}
	}
	return _difference;
};

export const getEntityTitles = (componentsById: { [id: string]: IComponentUnion }, activeSceneId: string) => {
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	const srSpatialComponents = getSpatialSrComponentsForScene(componentsById, activeScene);
	const sceneEntityIds = (componentsById[activeSceneId] as ISceneComp)?.children;
	return [...sceneEntityIds, ...srSpatialComponents].reduce((sceneScopedTitles, entityId) => {
		const component = componentsById[entityId];
		if (isAbstractComponent(component)) return sceneScopedTitles;
		sceneScopedTitles[entityId] = component.title;
		return sceneScopedTitles;
	}, {} as { [id: string]: string });
};

export const getCopiedEntityActionPayload = (
	store: EmptyObject & {
		userReducer: IUserState;
		contentReducer: IContentReducer;
	},
	numberPastes: number
): IOnPasteCopiedEntities_Global_Payload => {
	const copiedIds = store.userReducer.copiedEntityIds!;
	const activeSceneId = store.userReducer.activeSceneId!;
	const componentsById = store.contentReducer.contentDoc.componentsById;
	const copiedEntityTitle = (store.contentReducer.contentDoc.componentsById[copiedIds[0]] as ISpatialComponentUnion).title;
	const entityTitles = getEntityTitles(JSON.parse(JSON.stringify(componentsById)), activeSceneId);
	const newTitle = copiedIds.length === 1 ? getEntityDropTitle(entityTitles, copiedEntityTitle) : '';
	const isImageTrackedScene = (store.contentReducer.contentDoc.componentsById[activeSceneId] as ISceneComp)?.trackingType === ITrackingTypes.image;
	const isCurvedImageData =
		store.contentReducer.contentDoc?.tracking?.image?.targetType !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR &&
		typeof store.contentReducer.contentDoc?.tracking?.image?.targetType !== 'undefined';
	const isCurvedImageTrackedScene = isImageTrackedScene && isCurvedImageData;
	const copiedEntityIsWrapped = 'isSnappedToTarget' in componentsById[copiedIds[0]] ? !!(componentsById[copiedIds[0]] as ICurveComponentUnion).isSnappedToTarget : false;

	// Can use index zero ( copiedIds[0] ) as user can't copy from more than 1 scene at a time
	const copiedEntityOriginalSceneId = Object.entries(componentsById).reduce((originalSceneId, [entityId, entity]) => {
		if (entity.type === IComponentType.Scene) {
			if (entity.children.includes(copiedIds[0])) {
				originalSceneId = entityId;
			}
		}
		return originalSceneId;
	}, activeSceneId); // Init the accumulator to the activeSceneId
	let xCanvasOffset = 0.05;
	let yCanvasOffset = 0.05;
	if (copiedEntityOriginalSceneId !== activeSceneId) {
		// Pasted onto a scene that is different from the scene it was on
		xCanvasOffset = 0;
		yCanvasOffset = 0;
	}
	if (isCurvedImageTrackedScene && copiedEntityIsWrapped) {
		xCanvasOffset = 0;
	}
	const offset: ITuple3 = [xCanvasOffset * numberPastes + xCanvasOffset, -yCanvasOffset * numberPastes - yCanvasOffset, 0];
	return {
		offset,
		newTitle,
		activeSceneId,
		copiedIds,
	};
};

export const dynamicSortByProperty = (property: string) => {
	let sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	// eslint-disable-next-line
	return (a: any, b: any) => {
		/* next line works with strings and numbers,
		 * and you may want to customize it to your needs
		 */
		const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
};

export const dataURItoBlob = (dataURI: string) => {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	//Old Code
	//write the ArrayBuffer to a blob, and you're done
	//var bb = new BlobBuilder();
	//bb.append(ab);
	//return bb.getBlob(mimeString);

	//New Code
	return new Blob([ab], { type: mimeString });
};

// export const convertZmlDateStringToDate = (s: string): Date => {
// 	let date = s.replace(/((st|th|nd)\s(of))/, '')
// 	date = date.replace('at ', '');
// 	return  new Date(date);
// }

export const objUrlToFile = async (url: string, name: string) => {
	const blob = await fetch(url).then((r) => r.blob());
	return new File([blob], name);
};

// const largestSide = (inputArr: number[]) => {
// 	if (inputArr[0] > inputArr[1] && inputArr[0] > inputArr[2]) return 0;
// 	if (inputArr[1] > inputArr[0] && inputArr[1] > inputArr[2]) return 1;
// 	if (inputArr[2] > inputArr[0] && inputArr[2] > inputArr[1]) return 2;
// 	return 0;
// };

export const getInitialZPos = (args: {
	curvedTrackingRadius: number;
	isCurvedImageTrackedScene: boolean;
	sceneTrackingType: ITrackingTypes;
	isScreenRelativeMode: boolean;
	faceTrackingLandmark?: IFaceLandmark;
}) => {
	const { curvedTrackingRadius, isCurvedImageTrackedScene, sceneTrackingType, isScreenRelativeMode, faceTrackingLandmark } = args;
	let zPos = curvedTrackingRadius > 0 && isCurvedImageTrackedScene ? curvedTrackingRadius + 0.25 : 0;
	if (sceneTrackingType === ITrackingTypes.face && !isScreenRelativeMode && (faceTrackingLandmark === IFaceLandmark.origin || typeof faceTrackingLandmark === 'undefined')) zPos = 1.1;
	return zPos;
};

export const sortObjectArrayByProperty = (arr: any[], property: string) => {
	function compare( a: any, b: any ) {
		if ( a[property]< b[property] ){
		  return -1;
		}
		if ( a[property] > b[property] ){
		  return 1;
		}
		return 0;
	}
	  
	return arr.slice().sort( compare );
}



// export const getEmitterDropCoordinates = (dimensions: ITuple3, category: IEmitterCategory, sceneTrackingType: ITrackingTypes, isFlatOrientation: boolean, xPos = 0, yPos = 0, zPos = 0) => {
// 	// console.log('yPos', yPos)
// 	let position: number[] = [xPos, 0, zPos];
// 	let rotation: number[] = [0, 0, 0];

// 	if (sceneTrackingType === ITrackingTypes.face) {
// 		switch (category) {
// 			case 'rain':
// 				case 'confetti':
// 			case 'snow': {
// 				position = [xPos, yPos + 2, 0];
// 				break;
// 			}
// 			case 'fire':
// 			default:
// 				position = [xPos, 0, 1.3];
// 				break;
// 		}
// 	}

// 	if (sceneTrackingType === ITrackingTypes.image) {
// 		switch (category) {
// 			case 'confetti':
// 			case 'rain':
// 			case 'snow': {
// 				if (isFlatOrientation) {
// 					position = [xPos, 0.025, yPos + 2.5];
// 				} else {
// 					position = [xPos, yPos + 2.5, 1.4];
// 				}
// 				break;
// 			}
// 			default:
// 			case 'fire': {
// 				position = [xPos, 0, 0.2];
// 				break;
// 			}
// 		}
// 	}

// 	if (sceneTrackingType === ITrackingTypes.world) {
// 		switch (category) {
// 			case 'confetti':
// 			case 'rain':
// 			case 'snow': {
// 				position = [xPos, 4, 0];
// 				break;
// 			}
// 			default:
// 			case 'sparkles': {
// 				position = [xPos, 2.01, 0];
// 				break;
// 			}
// 			case 'fire': {
// 				position = [xPos, 0.2, 0];
// 				break;
// 			}
// 		}
// 	}

// 	if (isFlatOrientation && sceneTrackingType === ITrackingTypes.image) {
// 		rotation = [90, 0, 0];
// 	}

// 	// console.log('return position', position, 'rotation', rotation)
// 	return { position, rotation };
// }

// export const getModel3dDropCoordinates = (args: { dimensions: ITuple3, sceneTrackingType: ITrackingTypes, isCurvedImageTrackedScene: boolean; isFlatOrientation: boolean, isScreenRelativeMode: boolean, xPos: number, yPos: number, zPos: number}) => {
// 	const { dimensions, sceneTrackingType, isScreenRelativeMode, isCurvedImageTrackedScene, isFlatOrientation, xPos, yPos, zPos } = args;
// 	let position: number[] = [xPos, yPos, zPos];
// 	let rotation: number[] = [0, 0, 0];

// 	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

// 	const biggestSide = largestSide(dimensions);
// 	// Adjust model position depending on the aspect ratio of the 3D model
// 	switch (biggestSide) {
// 		case 0:
// 			position = [xPos, yPos, dimensions[2] / dimensions[0]];
// 			break;
// 		case 1:
// 			position = [xPos, yPos, dimensions[2] / dimensions[1]];
// 			break;
// 		case 2:
// 			if (dimensions[0] > dimensions[1]) {
// 				position = [xPos, yPos, dimensions[2] / dimensions[0]];
// 			} else {
// 				position = [xPos, yPos, dimensions[2] / dimensions[1]];
// 			}
// 			break;
// 	}

// 	// If we are in flat orientation, image tracked mode then rotate before placing
// 	if (isFlatOrientation && sceneTrackingType === ITrackingTypes.image) {
// 		rotation = [90, 0, 0];
// 		switch (biggestSide) {
// 			case 0:
// 				position = [position[0], position[1], dimensions[1] / dimensions[0]];
// 				break;
// 			case 1:
// 				position = [position[0], position[1], dimensions[1]];
// 				break;
// 			case 2:
// 				if (dimensions[0] > dimensions[1]) {
// 					position = [position[0], position[1], dimensions[1] / dimensions[2]];
// 				} else {
// 					position = [position[0], position[1], dimensions[1]];
// 				}
// 				break;
// 			default:
// 		}
// 	}

// 	// If this is world tracking, adjust model position accordingly
// 	if (sceneTrackingType === ITrackingTypes.world) {
// 		position = [xPos, dimensions[1], 0];
// 	}

// 	// If this is curved scene, adjust model position accordingly
// 	if (isCurvedImageTrackedScene) {
// 		position = [xPos, dimensions[1], (zPos + dimensions[2])];
// 	}

// 	return { position, rotation };
// };

// export const getButtonDropCoordinates = (args: {
// 	scale: IVector3;
// 	sceneTrackingType: ITrackingTypes;
// 	isFlatOrientation: boolean;
// 	isScreenRelativeMode: boolean;
// 	xPos: number;
// 	yPos: number;
// 	zPos: number;
// }) => {
// 	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
// 	let { yPos } = args;
// 	if (sceneTrackingType === ITrackingTypes.world && !isScreenRelativeMode && yPos <= 0 + scale[1]) {
// 		yPos = scale[1];
// 	}
// 	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
// 	let rotation: number[] = [0, 0, 0];

// 	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

// 	position = [xPos, sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

// 	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
// 	return { position, rotation };
// };

// export const getTextDropCoordinates = (args: {
// 	scale: IVector3;
// 	sceneTrackingType: ITrackingTypes;
// 	isFlatOrientation: boolean;
// 	isScreenRelativeMode: boolean;
// 	xPos: number;
// 	yPos: number;
// 	zPos: number;
// }) => {
// 	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
// 	let { yPos } = args;
// 	if (sceneTrackingType === ITrackingTypes.world && !isScreenRelativeMode && yPos <= 0 + scale[1]) {
// 		yPos = scale[1];
// 	}
// 	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
// 	let rotation: number[] = [0, 0, 0];

// 	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

// 	position = [xPos, sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

// 	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
// 	return { position, rotation };
// };

// export const getVideoDropCoordinates = (args: {
// 	scale: IVector3;
// 	sceneTrackingType: ITrackingTypes;
// 	isFlatOrientation: boolean;
// 	isScreenRelativeMode: boolean;
// 	xPos: number;
// 	yPos: number;
// 	zPos: number;
// }) => {
// 	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
// 	let { yPos } = args;
// 	if (sceneTrackingType === ITrackingTypes.world && !isScreenRelativeMode && yPos <= 0 + scale[1]) {
// 		yPos = scale[1];
// 	}

// 	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
// 	let rotation: number[] = [0, 0, 0];

// 	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

// 	position = [position[0], sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

// 	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
// 	return { position, rotation };
// };

// export const getImageDropCoordinates = (args: {
// 	scale: IVector3;
// 	sceneTrackingType: ITrackingTypes;
// 	isFlatOrientation: boolean;
// 	isScreenRelativeMode: boolean;
// 	xPos: number;
// 	yPos: number;
// 	zPos: number;
// }) => {
// 	const { scale, sceneTrackingType, isScreenRelativeMode, isFlatOrientation, xPos, zPos } = args;
// 	const { yPos } = args;
// 	let position: number[] = [xPos, yPos, (isScreenRelativeMode ? 0 : zPos)];
// 	let rotation: number[] = [0, 0, 0];

// 	if (isScreenRelativeMode || sceneTrackingType === ITrackingTypes.face) return { position, rotation };

// 	position = [position[0], sceneTrackingType === ITrackingTypes.world ? scale[1] : yPos, sceneTrackingType === ITrackingTypes.image && isFlatOrientation ? scale[1] : zPos];

// 	rotation = isFlatOrientation && sceneTrackingType === ITrackingTypes.image ? [90, 0, 0] : [0, 0, 0];
// 	return { position, rotation };
// };

export const filterArrayByAnother = (arr1: string[], arr2: string[]) => {
	const filtered = arr1.filter((el) => {
		return arr2.indexOf(el) === -1;
	});
	return filtered;
};

export const prependHttps = (str: string) => {
	return str.startsWith('//') ? `https:${str}` : str;
};

export const addUrlScheme = (str: string) => {
	return str.startsWith('https://') || str.startsWith('http://') ? str : `https://${str}`;
};

export const appendImg = (str: string, quality?: 'hq') => {
	const q = !quality ? '' : `.${quality}`;
	return str.endsWith(`/img${q}`) ? str : `${str}/img${q}`;
};

export const appendM3u8 = (str: string, resolution?: 'auto' | 'vr') => {
	const res = resolution ?? 'auto';
	return str.endsWith('/auto.m3u8') ? str : `${str}/${res}.m3u8`;
};

export const appendMp3 = (str: string) => {
	return str.endsWith('/aud.mp3') ? str : `${str}/aud.mp3`;
};

export const getHighestActiveRenderOrder = (componentsById: { [id: string]: IComponentUnion }, activeSceneId: string, isScreenRelativeMode: boolean): number => {
	const activeScene = componentsById[activeSceneId] as ISceneComp;
	if (isScreenRelativeMode) {
		const [activeScreenContentId] = activeScene.children.filter((id) => componentsById[id].type === IComponentType.ScreenContent);
		const screenRelativeEntityIds = (componentsById[activeScreenContentId] as IScreenContent).children.reduce((screenRelativeIds, anchorGroupId) => {
			return [...screenRelativeIds, ...(componentsById[anchorGroupId] as IScreenAnchorGroup).children];
		}, [] as string[]);
		return screenRelativeEntityIds.reduce((highestRenderOrder, entityId) => {
			const entity = componentsById[entityId] as IScreenComponentUnion;
			if (isAbstractComponent(entity) || (entity.type == IComponentType.Button && entity.subCategory === IButtonSubCategory.snapshot)) return highestRenderOrder;
			if (entity.renderOrder > highestRenderOrder) highestRenderOrder = entity.renderOrder;
			return highestRenderOrder;
		}, 0);
	}
	const faceTrackedComponentIds = getFaceTrackedComponentIds(activeSceneId, componentsById);
	return [...activeScene.children, ...faceTrackedComponentIds].reduce((highestRenderOrder, entityId) => {
		const entity = componentsById[entityId];
		if (isAbstractComponent(entity)) return highestRenderOrder;
		if (entity.renderOrder > highestRenderOrder) highestRenderOrder = entity.renderOrder;
		return highestRenderOrder;
	}, 0);
};

export const convertToModel3d = async (fsModel3d: FsModel3D, d: { x: number, y: number, z: number }): Promise<IModel3d> => {
    const model = await fsModel3d.getModelData()
	const dimensions = [d.x, d.y, d.z] as ITuple3;
	let animations: IModel3dAnimation[] = [];
	const position = [0, 0, 0];
	const rotation = [0, 0, 0];

	// if model wider than tall make width half target size width
	const widerThanTall = dimensions[0] > dimensions[1];
	const scale = [
		!widerThanTall ? dimensions[0] / dimensions[1] : 1,
		!widerThanTall ? 1 : dimensions[1] / dimensions[0],
		!widerThanTall ? dimensions[2] / dimensions[1] : dimensions[2] / dimensions[0],
	];

    if (typeof model !== 'undefined') {
        for (const name of Object.keys(model.animations)) {
            animations.push({ name, duration: (model.animations[name].lengthMS || 0) / 1000 });
        }
    }

	return {
		type: IComponentType.Model3d,
		id: MathUtils.generateUUID(),
		scale,
		position,
		originalBBox: [...dimensions],
		dimensions,
		animations,
		renderOrder: 0,
		rotation,
		title: fsModel3d.name,
		aspectRatioLocked: true,
		isLocked: false,
		isHidden: false,
		scalesInverted: [false, false],
		filestoreId: fsModel3d.id,
		isAnimated: Boolean(animations)
	};
};

export const getImageAspectRatio = (imgUrl: string): Promise<number> => {
	return new Promise((res, _rej) => {
		const imgObj = new Image();
		imgObj.addEventListener('load', function () {
			res(this.naturalWidth / this.naturalHeight);
		});
		imgObj.src = imgUrl;
	});
};

// export const removeDragStateFromEntity = (entity: ISpatialComponentUnion & IDragState) => {
// 	// If from a drag event, discard dimensions (except if a Model3d which DOES have a .dimensions property) and clickOffset
// 	if ('clickOffset' in entity) delete entity.clickOffset;
// 	if ('dimensions' in entity && entity.type !== IComponentType.Model3d) delete (entity as ISpatialComponentUnion & IDragState).dimensions;
// 	return entity as ISpatialComponentUnion;
// };

export const getModel3dAnimations = async (entity: IModel3d) => {
	const model3D = filestore.load<FsModel3D>(entity.filestoreId)
	const model = await model3D.getModelData()
	if (typeof model === 'undefined') return [];
	const animations: { name: string; duration: number }[] = [];
	for (const name of Object.keys(model.animations)) {
		animations.push({ name, duration: (model.animations[name].lengthMS || 0) / 1000 });
	}
	return animations;
};

export const finaliseEntity = (args: {
	entity: ISpatialComponentUnion;
	sceneTrackingType: ITrackingTypes;
	isFlatOrientation: boolean;
	isScreenRelativeMode: boolean;
	disableScreenRelativeScaleAdjustment?: boolean;
	isCurvedImageTrackedScene?: boolean;
	entityTitles: { [id: string]: string };
	renderOrder: number;
	x?: number;
	y?: number;
	z?: number;
	rotation?: ITuple3;
}): ISpatialComponentUnion => {
	const { entity, rotation, entityTitles, sceneTrackingType, isFlatOrientation, isScreenRelativeMode, isCurvedImageTrackedScene, disableScreenRelativeScaleAdjustment, renderOrder, x, y, z } = args;
	const { position = [0, 0, 0], rotation: _rotation = [0, 0, 0] } = getEntityDropCoordinates({ entity, sceneTrackingType, isFlatOrientation, isCurvedImageTrackedScene: !!isCurvedImageTrackedScene, isScreenRelativeMode, x, y, z }) || {};
	const scale = getEntityScale(entity, isScreenRelativeMode, !!disableScreenRelativeScaleAdjustment);
	const title = getEntityDropTitle(entityTitles, entity.title);

	// If SR mode, adjust font height and border width on buttons / text components
	if (isScreenRelativeMode) {
		if ('fontSize' in entity) entity.fontSize *= SR_AR_RATIO;
		if ('borderWidth' in entity && entity.borderWidth) entity.borderWidth *= SR_AR_RATIO;

		// Make the text boxes a little bigger so it doesn't break the bunding box
		if (entity.type == IComponentType.Text) scale[0] *= 1.4;
		if (entity.type == IComponentType.Text) scale[1] *= 1.4;
	}

	// const cleanEntity = removeDragStateFromEntity(entity as ISpatialComponentUnion & IDragState);

	const finalisedState = { ...entity, position, scale, rotation: rotation ?? _rotation, title, renderOrder, id: MathUtils.generateUUID() };
	if (entity.type === IComponentType.Model3d) {
		(finalisedState as IModel3d).originalBBox = entity.originalBBox || entity.scale;
		(finalisedState as IModel3d).castShadow = true;
		(finalisedState as IModel3d).receiveShadow = true;
	}
	if (entity.type === IComponentType.Video) {
		(finalisedState as IVideo).autoplay = true;
	}
	// if (entity.type === IComponentType.Button) {
	// 	(finalisedState as IButton).isTextScaleLocked = true;
	// }

	if (entity.type === IComponentType.Text3d) {
		(finalisedState as IText3d).aspectRatioLocked = true;
	}

	if (isCurveComponent(finalisedState)) finalisedState.curvature = 0;

	return finalisedState;
};

export const getEntityScale = (entity: ISpatialComponentUnion, isScreenRelativeMode: boolean, disableScreenRelativeScaleAdjustment: boolean) => {
	if (isScreenRelativeMode && !disableScreenRelativeScaleAdjustment) return getSREntityScale(entity, SCREEN_RELATIVE_CANVAS_SIZE_FACTOR);
	return entity.scale;
};

export const convertToImage = async (fsImage: FsImage): Promise<IImage> => {
	const aspectRatio = await fsImage.getAspectRatio()
	const position = [0, 0, 0];
	const rotation = [0, 0, 0];
	const hasAlpha = fsImage.name.includes('.png') ? true : false;
	let scale = [IMAGE_CANVAS_SIZE_FACTOR, IMAGE_CANVAS_SIZE_FACTOR / aspectRatio, 0];
	if (aspectRatio < 1) scale = [IMAGE_CANVAS_SIZE_FACTOR * aspectRatio, IMAGE_CANVAS_SIZE_FACTOR, 0];
	return {
		type: IComponentType.Image,
		id: MathUtils.generateUUID(),
		renderOrder: 0,
		curvature: 0,
		scale,
		position,
		rotation,
		title: fsImage.name,
		filestoreId: fsImage.id,
		hasAlpha,
		aspectRatioLocked: true,
		isLocked: false,
		isHidden: false,
		scalesInverted: [false, false]
	}
}

export const convertToVideo = async (fsVideo: FsVideo): Promise<IVideo> => {
	const title = fsVideo.name;
    const aspectRatio = await fsVideo.getAspectRatio();
	const hasAlpha = await fsVideo.getHasAlpha();
	const position = [0, 0, 0];
	const rotation = [0, 0, 0];
	let scale = [VIDEO_CANVAS_SIZE_FACTOR, VIDEO_CANVAS_SIZE_FACTOR / aspectRatio, 0];
	if (aspectRatio < 1) scale = [VIDEO_CANVAS_SIZE_FACTOR * aspectRatio, VIDEO_CANVAS_SIZE_FACTOR, 0];
	return {
		type: IComponentType.Video,
		id: MathUtils.generateUUID(),
        filestoreId: fsVideo.id,
		renderOrder: 0,
		curvature: 0,
		position,
		rotation,
		scale,
		title,
		aspectRatioLocked: true,
		isLocked: false,
		isHidden: false,
		scalesInverted: [false, false],
		hasAlpha
	}
}

export const convertToButton = (inputButton: IButtonBaseState): IButton => {
	const title = inputButton.text == BASIC_BUTTON_TEXT ? 'Button' : inputButton.text;
	const position = [0, 0, 0];
	const rotation = [0, 0, 0];
	const { scale, fontRgba, fontFamily, fontSize, text, color, borderRadius, borderRgba, borderWidth, textAlignment, textureUrl, svgUrl, category, subCategory } = inputButton;
	const retEntity: IButton = {
		type: IComponentType.Button,
		id: inputButton.id || MathUtils.generateUUID(),
		renderOrder: 0,
		curvature: 0,
		position,
		rotation,
		scale,
		title,
		aspectRatioLocked: true,
		isLocked: false,
		isHidden: false,
		scalesInverted: [false, false],
		fontRgba,
		fontFamily,
		fontSize,
		text,
		color,
		borderRadius,
		borderRgba,
		borderWidth,
		textAlignment,
		category,
	};
	if (subCategory) retEntity.subCategory = subCategory;
	if (textureUrl) retEntity.textureUrl = textureUrl;
	if (svgUrl) retEntity.svgUrl = svgUrl;
	return retEntity;
};

export const convertToText3d = (input: IText3dTextures): IText3d => {
	const {scale, fontRgba, fontFamily, material, text, fontSize, depth} = input;
	const position = [0, 0, 0];
	const rotation = [0, 0, 0];
	return {
		type: IComponentType.Text3d,
		id: input.id || MathUtils.generateUUID(),
		renderOrder: 0,
		position,
		rotation,
		scale,
		text,
		fontSize,
		textAlignment: ITextAlignment.center,
		fontRgba,
		fontFamily,
		title: text,
		aspectRatioLocked: false,
		isLocked: false,
		isHidden: false,
		scalesInverted: [false, false],
		material,
		depth: depth,
		curveSegments: 12,
		bevelEnabled: false,
		bevelThickness: 0,
		bevelSize: 0,
		bevelOffset: 0,
		bevelSegments: 12
	};

}

export const convertToText = (inputText: IFontStyles): IText => {
	const { scale, fontRgba, color, fontFamily } = inputText;
	const text = inputText.desc;
	const fontSize = ('troikaSize' in inputText ? inputText.troikaSize : inputText.fontSize) || 20;
	const title = 'Abc';
	const position = [0, 0, 0];
	const rotation = [0, 0, 0];
	return {
		type: IComponentType.Text,
		id: inputText.id || MathUtils.generateUUID(),
		renderOrder: 0,
		curvature: 0,
		position,
		rotation,
		scale,
		fontRgba,
		text,
		fontSize,
		textAlignment: ITextAlignment.left,
		color,
		fontFamily,
		title,
		aspectRatioLocked: false,
		isLocked: false,
		isHidden: false,
		scalesInverted: [false, false],
	};
};

export const buttonTitleShouldBeUpdated = (currentTitle: string, prospectiveTitle: string) => {
	const regex = new RegExp(`^${DEFAULT_BUTTON_TITLE}$|^${DEFAULT_BUTTON_TITLE} \\(\\d+\\)$`);
	if (regex.test(currentTitle) || diffBySingleCharacter(currentTitle, prospectiveTitle)) {
		return true;
	} else {
		return false;
	}
};

export const textTitleShouldBeUpdated = (currentTitle: string, prospectiveTitle: string) => {
	const regex = new RegExp(`^${DEFAULT_TEXT_TITLE}$|^${DEFAULT_TEXT_TITLE} \\(\\d+\\)$`);
	if (regex.test(currentTitle) || diffBySingleCharacter(currentTitle, prospectiveTitle)) {
		return true;
	} else {
		return false;
	}
};

export const isSpatialEntityType = (entityType: IComponentType) => {
	return entityType !== IComponentType.Root && entityType !== IComponentType.Scene;
};

const getFaceLandMarkGroupIds = (ids: string[], componentsById: { [id: string]: IComponentUnion }) => {
	return ids.filter((id) => componentsById[id].type === IComponentType.FaceLandmarkGroup);
};

const ImageHOC = withImageBehaviour(R3fImage);
const ButtonHOC = withButtonBehaviour(Button);
const VideoHOC = withVideoBehaviour(Video);
const TextHOC = withTextBehaviour(Text);
const Text3dHOC = withText3dBehaviour(Text3d);
const Model3dHOC = withModel3dBehaviour(Model3d);
const EmitterHOC = withEmitterBehaviour(Emitter);

const returnR3fComponentsByIds = (ids: string[], hiddenEntityIds: string[], componentsById: { [id: string]: IComponentUnion }, enabled: boolean, isPreview?: boolean) => {
	return ids.map((id) => {
		if (hiddenEntityIds.includes(id)) return null;

		const component = componentsById[id];
		if (isAbstractComponent(component)) return null;

		const props = { key: id, id, enabled };

		switch (component.type) {
			case IComponentType.Model3d:
				return <Model3dHOC {...props} />;
			case IComponentType.Button:
				return <ButtonHOC {...props} />;
			case IComponentType.Text:
				return <TextHOC {...props} />;
			case IComponentType.Text3d:
				return <Text3dHOC {...props} />
			case IComponentType.Image:
				return <ImageHOC {...props} />;
			case IComponentType.Video:
				return <VideoHOC {...props} />;
			case IComponentType.Emitter:
				return <EmitterHOC {...props} isPreview={isPreview} />;
			default:
				return null;
		}
	});
};

export const getFaceLandmarkDescriptionsByHeadbustType = (bustType: string): Record<IFaceLandmark, string> => {
	return Object.keys(FACE_LANDMARK_DATA[bustType]).reduce((acc, landmark) => {
		acc[landmark] = FACE_LANDMARK_DATA[bustType][landmark].text;
		return acc;
	}, {} as Record<IFaceLandmark, string>);
};

interface ICreateCanvasR3fComponentsInput {
	ids: string[];
	hiddenEntityIds: string[];
	componentsById: { [id: string]: IComponentUnion };
	enabled?: boolean;
	sceneTrackingType?: ITrackingTypes;
	isPreview?: boolean;
}

export const createCanvasR3fComponents = ({ ids, hiddenEntityIds, componentsById, enabled = true, sceneTrackingType, isPreview }: ICreateCanvasR3fComponentsInput) => {
	if (!ids) return null;

	if (sceneTrackingType === ITrackingTypes.face) {
		const landMarkGroupIds = getFaceLandMarkGroupIds(ids, componentsById);
		if (landMarkGroupIds.length === 0) return null;
		const positionsDict = getFaceLandmarkPositionsByHeadbustType('uar');
		const landmarkGroups = landMarkGroupIds.map((id) => {
			const { children, landmark } = componentsById[id] as IFaceLandmarkGroup;
			const position = positionsDict[landmark];

			return (
				<group key={id} name={landmark} position={position}>
					{returnR3fComponentsByIds(children, hiddenEntityIds, componentsById, enabled, isPreview)}
				</group>
			);
		});
		return landmarkGroups;
	}

	return returnR3fComponentsByIds(ids, hiddenEntityIds, componentsById, enabled, isPreview);
};

export const getScreenRelativeKey = (label: string) => {
	return Object.keys(SCREEN_RELATIVE_DEVICES).find((deviceKey) => SCREEN_RELATIVE_DEVICES[deviceKey].label == label);
};

// Assumes that id is the ID of a screen component, and that it belongs to activeScene
export const getParentAnchorGroup = (activeScene: ISceneComp, id: string, componentsById: { [k: string]: IComponentUnion }) => {
	const screenContentId = getScreenContentIdForScene(activeScene, componentsById);
	if (!screenContentId) return undefined;
	const anchorGroups = (componentsById[screenContentId] as IScreenContent).children;
	for (let i = 0; i < anchorGroups.length; i++) {
		if ((componentsById[anchorGroups[i]] as IScreenAnchorGroup).children.includes(id)) return anchorGroups[i];
	}
};

// Get the sceneId that a given entityId belongs to
export const getParentSceneId = (entityId: string, componentsById: { [k: string]: IComponentUnion }) => {
	for (const componentId in componentsById) {
		const component = componentsById[componentId] as IComponentUnion;
		if (component.type == IComponentType.Scene) {
			if (component.children.includes(entityId)) return componentId;
		}
		if (component.type == IComponentType.ScreenContent) {
			for (let i = 0; i < component.children.length; i++) {
				const anchorGroupId = component.children[i];
				const anchorGroup = componentsById[anchorGroupId] as IScreenAnchorGroup;
				if (anchorGroup.children.includes(entityId)) return getComponentParentIdById(componentId, componentsById);
			}
		}
		if (component.type == IComponentType.FaceLandmarkGroup) {
			const faceLandmarkGroup = componentsById[componentId] as IFaceLandmarkGroup;
			for (let i = 0; i < component.children.length; i++) {
				if (faceLandmarkGroup.children.includes(entityId)) return getComponentParentIdById(componentId, componentsById);
			}
		}
	}
};

// Return a string[] of all screen relative spatial entities within a scene
export const getSpatialSrComponentsForScene = (componentsById: { [id: string]: IComponentUnion }, scene: ISceneComp) => {
	let srComponents: string[] = [];
	const screenContentId = scene.children.filter((id) => componentsById[id].type == IComponentType.ScreenContent)[0];
	if (!screenContentId) return srComponents;
	const anchorGroups = (componentsById[screenContentId] as IScreenContent).children;
	for (let i = 0; i < anchorGroups.length; i++) {
		const anchorGroupChildren = (componentsById[anchorGroups[i]] as IScreenAnchorGroup).children;
		srComponents = [...srComponents, ...anchorGroupChildren];
	}
	return srComponents;
};

export const adjustRenderOrdersOnEntityRemoval = (componentsById: { [id: string]: IComponentUnion }, idToRemove: string) => {
	const componentsByIdCopy = JSON.parse(JSON.stringify(componentsById)); // Prevent weird mutation issues when removing full scene
	if (isAbstractComponent(componentsByIdCopy[idToRemove])) return;

	// Is spatial component, find it's parent scene
	const sceneId = getParentSceneId(idToRemove, componentsByIdCopy);
	const renderOrderToRemove = (componentsByIdCopy[idToRemove] as ISpatialComponentUnion).renderOrder;

	// Should never happen but because componentsById isn't ordered it can occur when a whole scene is removed - no-op but need to stop it crashing
	if (sceneId) {
		const scene = componentsByIdCopy[sceneId] as ISceneComp;
		const isArComponent = scene.children.includes(idToRemove);
		const componentsToAdjust = isArComponent ? scene.children.filter((id) => !isAbstractComponent(componentsByIdCopy[id])) : getSpatialSrComponentsForScene(componentsByIdCopy, scene);

		// Decrement all of the render orders above the one being removed
		componentsToAdjust.forEach((id) => {
			if ((componentsById[id] as ISpatialComponentUnion).renderOrder > renderOrderToRemove) {
				(componentsById[id] as ISpatialComponentUnion).renderOrder--;
			}
		});
	}
	return;
};

// By default returns activeSceneId for world, image tracked AR scenes and the DEFAULT constant for face / screen. If faceTrackingLandmark or screenAnchorPosition are present these take priority over DEFAULT constants
export const getParentIdForAddEntity = (d: {
	activeSceneId: string;
	sceneTrackingType: ITrackingTypes;
	isScreenRelativeMode: boolean;
	faceTrackingLandmark?: IFaceLandmark;
	screenAnchorPosition?: IScreenAnchorPositionType;
}) => {
	const { activeSceneId, faceTrackingLandmark, sceneTrackingType, isScreenRelativeMode, screenAnchorPosition } = d;
	let parentId: string | IFaceLandmark | IScreenAnchorPositionType = activeSceneId;
	if (sceneTrackingType === ITrackingTypes.face) parentId = faceTrackingLandmark ?? DEFAULT_FACE_LANDMARK;
	if (isScreenRelativeMode) parentId = screenAnchorPosition ?? DEFAULT_SCREEN_RELATIVE_ANCHOR_POSITION;
	return parentId;
};

export const isSceneContainsSpatialArComponent = (sceneId: string, componentId: string, componentsById: { [id: string]: IComponentUnion }) => {
	const component = componentsById[componentId];
	const scene = componentsById[sceneId] as ISceneComp;

	// If falsey componentId or sceneId
	if (!component || !scene) return false;

	// Abstract component
	if (isAbstractComponent(component)) return false;

	// World / Image tracked scenes check scene.children
	if (scene.children.includes(componentId)) return true;

	// Face tracked scenes check landmark groups
	if (scene.trackingType === ITrackingTypes.face) {
		const faceTrackingLandmarkGroupIds = scene.children.filter((id) => componentsById[id].type === IComponentType.FaceLandmarkGroup);
		return faceTrackingLandmarkGroupIds.reduce((containsSpatialArComponent, landmarkId) => {
			const landmarkGroup = componentsById[landmarkId] as IFaceLandmarkGroup;
			return containsSpatialArComponent || landmarkGroup.children.includes(componentId);
		}, false);
	}

	// If not found now then the componentId is either on another scene or on a SR layer
	return false;
};

export const getFaceTrackingAdjustmentFactor = (sceneTrackingType: ITrackingTypes, isScreenRelativeMode: boolean) => {
	if (sceneTrackingType !== ITrackingTypes.face || isScreenRelativeMode) return [1, 1, 1];
	return [200 / HEAD_MESH_DIMENSIONS[1], 200 / HEAD_MESH_DIMENSIONS[1], 200 / HEAD_MESH_DIMENSIONS[1]];
};

export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getEmitterCategoryDefaultTexture = (category: IEmitterCategory): string => {
	const emitterTexture = EMITTER_CATEGORY_TEXTURE_DICT[category];
	if (emitterTexture) return EMITTER_TEXTURE_DICT[emitterTexture].url;
	return '';
};

export const allArrayItemsAreEqual = (arr: (string | number)[]) => arr.every((v) => v === arr[0]);

export const allRgbaColorsAreEqual = (arr: IVector4[]): boolean | null =>
	arr.every((v) => {
		if (typeof v === 'undefined') return null;
		return v[0] === arr[0][0] && v[1] === arr[0][1] && v[2] === arr[0][2] && v[3] && arr[0][3];
	});

export const isLastWorldInStringMB = (str: string) => {
	const lastWord = str.split(" ").slice(-1)?.[0];
	return !!lastWord?.includes('MB');
}

export const getProjectSizeLimitFromString = (str: string) => {
	return str.split(" ").slice(-2)?.[0];
}

export const addTextLineBreaks = (str?: string) => {
	if (!str) return <></>
	return str.split('\n').map((text, index) => (
		<React.Fragment key={index}>
		  {text}
		  <br />
		</React.Fragment>
	));
}



export const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}