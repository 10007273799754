import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import BrowserError from './components/dom/BrowserError/BrowserError';
import { ISceneComp, ITrackingTypes } from './components/r3f/r3f-components/component-data-structure';
import * as settings from './settings';
import { store } from './store/index';
const App = React.lazy(() => import('./components/App/App'));
import { setupWebSocket } from './sync/index';
import { isSupportedBrowser } from './utils/compatibility';
import { MAINTENANCE_INTERVAL } from './utils/constants';
import { performMaintenanceCheck } from './utils/maintenance';
import Info20 from './assets/icons/Info20';
import './index.scss';
import { updateProjectScreenshot } from './utils/general';

// Check browser compatibility & show upgrade message if browser can't support designer
const browserIsCompatible = isSupportedBrowser();

// Initialize sentry before React
// TODO: add Sentry's React router integration https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
Sentry.init({
	dsn: settings.SENTRY_DSN,
	environment: settings.ENVIRONMENT,
	release: settings.VERSION,
	integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.httpClientIntegration()],
	tracesSampleRate: 4.0,
	normalizeDepth: 5,
	beforeSend: (event, hint) => {
		if (settings.ENVIRONMENT === 'local') {
			console.log(hint.originalException || hint.syntheticException);
			return null; // this drops the event and nothing will be sent to sentry
		}
		return event;
	}
})
// Add extra context to all Sentry exceptions
Sentry.addEventProcessor(function (event, hint) {
	const activeSceneId = store.getState().userReducer.activeSceneId;
	const activeSceneType = activeSceneId ? (store.getState().contentReducer?.contentDoc?.componentsById?.[activeSceneId] as ISceneComp)?.trackingType : null;
	const additionalContext = {
		socketConnected: store.getState().userReducer.socketConnected,
		undoDisabled: store.getState().userReducer.undoDisabled,
		redoDisabled: store.getState().userReducer.redoDisabled,
		canvasLoaded: store.getState().userReducer.canvasLoaded,
		projectLoadingProgress: store.getState().userReducer.projectLoadingProgress,
		projectLoadingFailure: store.getState().userReducer.projectLoadingFailure,
		selectedEntityIds: store.getState().userReducer.selectedEntityIds,
		isScreenRelativeMode: store.getState().userReducer.isScreenRelativeMode,
		is3dMode: store.getState().userReducer.is3dMode,
		entityMenuDragActive: store.getState().userReducer.entityMenuDragActive,
		hotKeys: store.getState().userReducer.hotKeys,
		activeSceneType,
		hint,
	};
	event.extra = {...additionalContext, ...event.extra};
	return event;
});

// show maintenance toast if CMS is going to be in maintenance mode
setInterval(performMaintenanceCheck, MAINTENANCE_INTERVAL);

// set local storage particle state to show debug section
const urlParams = new URLSearchParams(window.location.search);
const debugParticles = urlParams.get('debugparticles');
if (typeof debugParticles !== 'undefined' && debugParticles !== null) {
	localStorage.setItem('debugparticles', debugParticles);
}

const beforeUnloadHandler = (_: BeforeUnloadEvent) => {
	const projectId = store?.getState?.()?.userReducer?.project?.id;
	const currentActiveSceneId = store?.getState?.()?.userReducer?.activeSceneId || '';
	const currentIsArModeDisabled = (store?.getState?.()?.contentReducer?.contentDoc?.componentsById?.[currentActiveSceneId] as ISceneComp)?.trackingType === ITrackingTypes.noTrackingScreen;
	if (!!projectId && !!currentActiveSceneId) updateProjectScreenshot(projectId, currentIsArModeDisabled);
};

window.addEventListener('beforeunload', beforeUnloadHandler);

// Render app
(async (store) => {
	const container = document.getElementById('root') || document.createElement('div');
	const root = createRoot(container);

	if (!browserIsCompatible) return root.render(<BrowserError />)

	try {
		await setupWebSocket(store)
	} catch(err) {
		console.error(err)
		Sentry.captureException(err)
		return root.render(getLoadingErrorElement())
	}
	// At this point we can know that the content doc is empty ( or otherwise malformed )
	// Log to Sentry if we experience contentDoc = { } situation
	const contentDoc = store.getState().contentReducer.contentDoc;
	if (Object.entries(contentDoc).length < 2) {
		Sentry.captureException(new Error('Empty content doc - manual exception.'));
		return root.render(getLoadingErrorElement())
	}
	// Don't` render at 5% because it leads to a flicker when it has to re-render
	// index.tsx gets reloaded when syncdoc changes the window.location just before action SET_PROJECT_LOADING_PROGRESS with 20% gets dispatched
	if (store.getState().userReducer.projectLoadingProgress <= 5) return null;

	root.render(
		<Sentry.ErrorBoundary>
			<Provider store={store}>
				<React.Suspense fallback={null}>
					<App store={store} />
				</React.Suspense>
			</Provider>
		</Sentry.ErrorBoundary>
	)
})(store);


function getLoadingErrorElement() {
	return <div style={{
		display: 'flex',
		width: '100vw',
		height: '100vh',
		justifyContent: 'center',
		alignItems: 'center',
	}}>
		<div style={{
			backgroundColor: '#f2f2f2',
			borderRadius: '24px',
			color: '#073158',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '100px',
		}}>
			<Info20 height="75" width="75" />
			<h1 style={{ marginTop: '50px' }}>Oops! Something went wrong</h1>
			<p style={{ fontSize: '20px' }}>Designer encountered an error and was unable to load. Please refresh and try again.</p>
		</div>
	</div>;
}