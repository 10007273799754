
/**
 * Converts a number of bytes to a human-readable string with a file size suffix.
 *
 * @param bytes The file size in bytes.
 * @returns The human-readable string representation of the size.
 */
export function formatBytes(bytes: number): string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${suffixes[i]}`;
}
